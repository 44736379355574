<div class="map-menu" [class.show]="show">
  <div class="content pt-4">

    <ng-container *ngIf="dataService.$types | async as types">

      <div class="btn btn-sm ml-2 mb-4"
           [class.btn-primary]="!dataService.hideDifficultyRatings"
           [class.btn-light]="dataService.hideDifficultyRatings"
           (click)="toggleDifficulty()">Difficulty Ratings</div>
      <div class="btn btn-sm btn-secondary ml-2 mb-4" (click)="selectAll(types)">Select All</div>
      <div class="btn btn-sm btn-secondary ml-2 mb-4" (click)="deselectAll()">Deselect All</div>

      <div class="item d-flex px-3 align-items-center" *ngFor="let type of types.data.categories" (click)="toggleType(type.slug)">
        <div class="image mr-3">
          <img [src]="type.menuIcon[0].icon" [class.off]="!this.dataService.hidden.includes(type.slug)">
        </div>
        <h6 class="m-0 py-3" [style]="{color: this.dataService.hidden.includes(type.slug) ? '#'+type.hex : 'unset'}">{{type.title}}</h6>
      </div>
    </ng-container>

  </div>
</div>

<div class="dim-overlay" *ngIf="show" (click)="toggle(false)"></div>
