import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FaqComponent} from "./routes/faq/faq.component";
import {HomeComponent} from "./routes/home/home.component";
import {ContactComponent} from "./routes/contact/contact.component";
import {ContentComponent} from "./routes/content/content.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'content/:slug', component: ContentComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact', component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
