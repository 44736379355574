<div class="directions-text p-3 d-none d-print-block" *ngIf="renderInterval && directionsData">
  <h2>Directions to <strong>{{destinationMetaData.title}}</strong></h2>
  <ul>
    <li class="step" *ngFor="let step of directionsData.steps">
      <span [innerHTML]="step.instructions"></span> ({{ step.distance.text }}, {{ step.duration.text }})
    </li>
  </ul>
</div>

<div class="map-container">
  {{map?.getZoom()}}

  <google-map [height]="'100%'" [width]="'100%'" [center]="centre" [zoom]="defaultZoom" (mapDragstart)="touch()">

    <ng-container *ngIf="dataService.$accessibility | async as icons">
      <ng-container *ngFor="let marker of icons.data.entries">
        <map-marker
          *ngIf="!marker.accessibilityType[0].smallIcon || map?.getZoom() > 17"
          [className]="'art-marker'"
          [position]="marker.position"
          [title]="marker.title"
          [options]="{'icon': {'url': marker.accessibilityType[0].mapIcon[0].icon, scaledSize: marker.accessibilityType[0].smallIcon ? {width: 17, height: 17} : {width: 40, height: 53}}, 'opacity': (this.focusedMarkers.length !== 0 && !this.focusedMarkers.includes(marker.slug) ? 0.1 : 1)  }"
          (mapClick)="markerClick(marker.slug)"
          [clickable]="!(this.focusedMarkers.length !== 0 && !this.focusedMarkers.includes(marker.slug))"
        ></map-marker>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!dataService.hideDifficultyRatings &&  dataService.$difficultyRatings | async as icons">
      <ng-container *ngFor="let marker of icons.data.entries">
        <map-polyline
          [path]="[marker.position, marker.position2]"
          [options]="{'strokeColor': marker.difficultyRating, 'strokeOpacity': 0.6, 'strokeWeight': 7}">
        </map-polyline>
      </ng-container>
    </ng-container>

  </google-map>
</div>
<!--
[options]="{'icon': {'url': marker.featureImage[0].icon, scaledSize: {width: 40, height: 53}}, 'opacity': (this.focusedMarkers.length !== 0 && !this.focusedMarkers.includes(marker.slug) ? 0.1 : 1) }"
 -->
<div class="directions d-print-none" *ngIf="renderInterval">
  <div class="icon">
  </div>

  <div class="text">
    <div class="left">
      <div>
        <h4 *ngIf="directionsData && directionsData.steps.length && destinationDistance >= 50" [innerHTML]="directionsData.steps[0].instructions"></h4>
        <h4 *ngIf="!directionsData">Loading...</h4>
        <h4 *ngIf="directionsData && destinationDistance < 50">Arrived at destination</h4>
        <p *ngIf="directionsData && destinationDistance >= 50"><b>{{destinationMetaData.title}}</b> <span> ({{directionsData.distance.text}}, {{directionsData.duration.text}})</span></p>
        <p *ngIf="!directionsData || directionsData && destinationDistance < 50">{{destinationMetaData.title}}</p>
      </div>
    </div>
    <div class="right">
      <a *ngIf="!directionsData || destinationDistance >= 50" (click)="showPath()" class="btn btn-outline-secondary">Show Path</a>
      <a *ngIf="!directionsData || destinationDistance >= 50" (click)="recentre()" class="btn btn-outline-secondary">Recentre</a>
      <ng-container *ngIf="$destinationNav | async as destinationNav">
        <a *ngIf="directionsData && destinationDistance < 50 && destinationNav.next" (click)="markerClick(destinationNav.next.slug)" class="btn btn-outline-secondary">Next</a>
      </ng-container>
      <a (click)="markerClick(destinationMetaData.slug)" class="btn btn-outline-secondary">
        <ng-container *ngIf="directionsData && destinationDistance < 50">View</ng-container>
        <i class="fa fa-fw fa-close" *ngIf="!(directionsData && destinationDistance < 50)"></i>
      </a>
    </div>
  </div>
</div>
