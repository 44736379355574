import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {GoogleMap} from "@angular/google-maps";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService, ListNav} from "../../services/data.service";
import {AbsoluteOrientationSensor} from "motion-sensors-polyfill/src/motion-sensors.js";
import {Observable} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {PublicArtworksQuery} from "../../graphql/gql.overrides";
import TravelMode = google.maps.TravelMode;
import {AccessibilityQuery} from "../../../generated/graphql";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy{
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap

  public gpsMarker: google.maps.Marker;
  public gpsPositionActual: google.maps.LatLngLiteral;
  public gpsPosition: google.maps.LatLngLiteral;
  private gpsUpdate;

  public centre: google.maps.LatLngLiteral = {
    lat: -23.6980,
    lng: 133.8807
  }
  public defaultZoom = 17;

  public mapStyle: google.maps.MapTypeStyle[] =
    [
      {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
          {
            "saturation": 32
          },
          {
            "lightness": -3
          },
          {
            "visibility": "on"
          },
          {
            "weight": 1.18
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
          {
            "saturation": -70
          },
          {
            "lightness": 14
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "saturation": 100
          },
          {
            "lightness": -14
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
          {
            "lightness": 12
          }
        ]
      }
    ];

  public options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    streetViewControl: false,
    mapTypeControl: false,
    gestureHandling: "greedy",
    clickableIcons: false,
    disableDefaultUI: true,
    maxZoom: 19,
    minZoom: 13,
    styles: this.mapStyle
  }

  private orientation: AbsoluteOrientationSensor;

  private directionsService: google.maps.DirectionsService = new google.maps.DirectionsService;
  private directionsRenderer: google.maps.DirectionsRenderer = new google.maps.DirectionsRenderer;
  public directionsData: google.maps.DirectionsLeg;
  public renderInterval;
  public destinationMetaData: any; //AccessibilityQuery['entries'][0];
  public destinationDistance:number = null;
  public $destinationNav: Observable<ListNav> = null;
  private renderCentered:boolean = false;
  private posAtLastDirectionsRequest = null

  public focusedMarkers: string[] = [];
  public gpsTracking = true;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public dataService: DataService,
    private toastr: ToastrService,
    public cdRef: ChangeDetectorRef,
    public ngZone: NgZone
  ) { }

  ngAfterViewInit(): void {
    this.map.options = this.options;

    this.gpsMarker = new google.maps.Marker({
      map: this.map._googleMap,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: 'blue',
        strokeWeight: 6,
        scale: 6,
        rotation: 0
      },
      position: {
        lat: -23.6980,
        lng: 133.8807,
      },
      draggable: true
    })

    this.gpsMarker.addListener('dragend', (e) => {
      this.gpsPosition = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      }

      this.gpsTracking = false;
    })

    navigator.geolocation.watchPosition((pos)=>{this.updateGPS({lat: pos.coords.latitude, lng: pos.coords.longitude})}, null, {
      enableHighAccuracy: true,
      maximumAge: 0
    })

    this.orientation = new AbsoluteOrientationSensor({ frequency: 60 });
    this.orientation.addEventListener('reading', (e) => {
        var q = e.target.quaternion;
        let alpha = Math.atan2(2*q[0]*q[1] + 2*q[2]*q[3], 1 - 2*q[1]*q[1] - 2*q[2]*q[2])*(180/Math.PI);
        if(alpha < 0) alpha = 360 + alpha;

        let icon = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeColor: 'blue',
          strokeWeight: 3,
          scale: 6,
          rotation: 360 - alpha
        }
        this.gpsMarker.setIcon(icon);
    })
    this.orientation.start();

    this.map.zoomChanged.subscribe(() => this.cdRef.detectChanges());

  }

  ngOnDestroy(): void {
    clearInterval(this.gpsUpdate);
  }

  public touch(): void {
    this.renderCentered = false;
  }

  public markerClick(slug: string): void {
    this.ngZone.run(() =>{
      this.stopRendering()
      this.router.navigate(['/content/'+slug], {queryParamsHandling: 'preserve', preserveFragment: true});
      this.cdRef.detectChanges();
    })
  }

  public setFocusedMarkers(markers: string[]): void {
    this.focusedMarkers = markers;
    this.cdRef.detectChanges();
  }

  public clearFocusedMarkers(): void {
    this.setFocusedMarkers([]);
  }

  public updateGPS(position: google.maps.LatLngLiteral): void {
    this.gpsPositionActual = {
      lat: position.lat,
      lng: position.lng,
    }

    if (!this.gpsTracking) return;

    this.gpsPosition = {
      lat: position.lat,
      lng: position.lng,
    }

    this.gpsMarker.setPosition(this.gpsPosition)
    if (this.isDirecting()) {
      if (this.renderCentered) this.centreMap(this.gpsPosition, this.map.getZoom());
      this.destinationDistance = this.haversine_distance(this.gpsPosition, this.destinationMetaData.position)
    }
  }

  public centreMap(pos: google.maps.LatLngLiteral, zoom?: number): void {
    if (!pos || !pos.lat || !pos.lng) { return; }
    // sometimes the map isn't ready if this is called on load, in which case we delay the centreing. this is ugly, but it works.
    if (!this.map.getProjection()) {
      setTimeout (() => {
        this.centreMap(pos)
      },100)
      return;
    }
    this.map.zoom = zoom ? zoom : this.defaultZoom;

    let viewHeight = window.innerHeight;
    let viewWidth = window.innerWidth;
    let headerHeight = document.querySelector('#header').scrollHeight;
    let mainContentStart = 0;
    try {
      mainContentStart = parseInt(window.getComputedStyle(document.querySelector('main .content')).getPropertyValue('margin-top').replace('px', ''));
    } catch (e) {}

    let yoffset = 0;
    let xoffset = 0;

    if (this.router.url.split("?")[0].split("#")[0] === '/') {
      yoffset = 0-(headerHeight/2);
    } else {
      yoffset = (viewHeight/2) - headerHeight - (mainContentStart/2);
    }

    // hardcoded :/
    if (viewWidth >= 1440) {
      xoffset = 180;
    }

    let centre = new google.maps.LatLng(pos.lat, pos.lng)
    let scale = Math.pow(2, this.map.getZoom());

    let worldCoordinateCenter = this.map.getProjection().fromLatLngToPoint(centre);
    let pixelOffset = new google.maps.Point((xoffset/scale),(yoffset/scale) ||0);

    let worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    );

    let newCenter = this.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

    this.map.panTo(newCenter);

    // map.setCenter(newCenter);
  }

  public getRoute(pos): Observable<google.maps.DirectionsLeg> {
    return new Observable<google.maps.DirectionsLeg>(subscriber => {
        const route: any = {
          origin: this.gpsMarker.getPosition(),
          destination: pos,
          travelMode: 'WALKING'
        }

        this.directionsService.route(route, (response, status) => {
          if (status !== 'OK') {
            subscriber.error('Unknown');
          } else {
            subscriber.next(response.routes[0].legs[0]);
          }
        });
    })
  }

  public renderRoute(start: google.maps.LatLngLiteral, end: google.maps.LatLngLiteral, waypoints?: google.maps.LatLng[]): Observable<google.maps.DirectionsResult> {
    return new Observable<google.maps.DirectionsResult>(subscriber => {

      let waypointsFixed: google.maps.DirectionsWaypoint[] = waypoints.map(w => {return {location: w, stopover: false}})

      const route: google.maps.DirectionsRequest = {
        origin: start,
        destination: end,
        travelMode: TravelMode.WALKING,
        waypoints: waypointsFixed
      };

      this.directionsService.route(route, (response, status) => {
        if (status === 'OK') {
          this.renderDirections(response);
          subscriber.next(response);
          subscriber.complete();
        } else {
          subscriber.error('Unknown');
        }
      })
    });

  }

  public startDirections (metaData: any, showPath = false) {
    clearInterval(this.renderInterval);

    this.checkGeoPermission()
    this.destinationMetaData = metaData;
    this.destinationDistance = this.haversine_distance(this.gpsPosition, this.destinationMetaData.position)
    // this.$destinationNav = this.dataService.getListNav(metaData.slug);
    this.directionsData = null;
    this.renderCentered = true;
    this.posAtLastDirectionsRequest = null;

    setTimeout(()=>{
      if (showPath) {
        this.showPath();
      } else {
        this.recentre()
      }
      this.setFocusedMarkers([this.destinationMetaData.slug])
    },200)

    this.renderInterval = setInterval(()=>{
      if (this.posAtLastDirectionsRequest && this.haversine_distance(this.posAtLastDirectionsRequest, this.gpsPosition) < 20){
        return;
      }

      this.posAtLastDirectionsRequest = this.gpsPosition;

      const route: any = {
        origin: this.gpsPosition,
        destination: metaData.position,
        travelMode: 'WALKING'
      };

      this.directionsService.route(route, (response, status) => {
        if (status === 'OK' && this.renderInterval) {
          this.directionsData = response.routes[0].legs[0];
          this.renderDirections(response);
          this.cdRef.detectChanges();
        }
      });
    }, 200)
  }

  public isDirecting (): boolean {
    return !!this.renderInterval;
  }

  public stopRendering (): void {
    clearInterval(this.renderInterval);
    this.renderCentered = false;
    this.clearFocusedMarkers();
    this.directionsRenderer.setMap(null);
    this.renderInterval = null;
    this.posAtLastDirectionsRequest = null;
  }

  public showPath(): void {
    this.renderCentered = false;
    this.posAtLastDirectionsRequest = null;

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.gpsPosition);
    bounds.extend(this.destinationMetaData.position);

    this.map.fitBounds(bounds)

    setTimeout(() => {
      this.map.zoom = this.map.getZoom() - 1;
    }, 500)

    // const listener = google.maps.event.addListener(this.map, "center_changed", () => {
    //   console.log(this.map.getZoom());
    //   this.map.zoom = this.map.getZoom() + 3;
    //   google.maps.event.removeListener(listener);
    // });
    //
    // console.log(listener);

  }

  public recentre(): void {
    this.centreMap(this.gpsPosition, 18);
    this.renderCentered = true;
    this.posAtLastDirectionsRequest = null;
  }

  private renderDirections (directions: google.maps.DirectionsResult) {
    this.directionsRenderer.setMap(this.map._googleMap);
    this.directionsRenderer.setOptions({
      suppressMarkers: true,
      preserveViewport: true
    })
    this.directionsRenderer.setDirections(directions);
  }

  private haversine_distance(mk1, mk2) {
    var R = 6371071.0; // Radius of the Earth in m
    var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
    var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    return d;
  }

  public requestOrientationPermissions():void {
    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
      (DeviceOrientationEvent as any).requestPermission()
        .then(permissionState => {})
        .catch(console.error);
    }
  }

  public checkGeoPermission(): void {
    navigator.geolocation.getCurrentPosition(()=>{}, err => {
      this.toastr.error('Enable location settings on your device & browser to use this feature.', 'GeoLocation not available')
    })
  }
}
