import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {
  AccessibilityGQL,
  AccessibilityQuery,
  AccessibilityTypesGQL, AccessibilityTypesQuery, DifficultyRatingsGQL, DifficultyRatingsQuery,
} from "../../generated/graphql";
import {map} from "rxjs/operators";
import {MapComponent} from "../components/map/map.component";
import {MenuComponent} from "../components/menu/menu.component";
import {PublicArtworksQuery} from "../graphql/gql.overrides";
import {ApolloQueryResult} from "@apollo/client/core";

export type ListNav = {
  prev?: PublicArtworksQuery['entries'][0],
  next?: PublicArtworksQuery['entries'][0],
  pos: number,
  total: number,
  name: string
}

export type ListData = {
  name: string,
  artworks: PublicArtworksQuery['entries'],
  walk: boolean
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public map: MapComponent;
  public menu: MenuComponent;

  public $types: Observable<ApolloQueryResult<AccessibilityTypesQuery>>;
  public $accessibility: Observable<ApolloQueryResult<AccessibilityQuery>>;
  public $difficultyRatings: Observable<ApolloQueryResult<DifficultyRatingsQuery>>;
  public $hidden: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(['accessible-toilets', 'accessible-parking']);

  public hidden: string[] = ['accessible-toilets', 'accessible-parking'];
  public hideDifficultyRatings = true;

  constructor(
    private typesGQL: AccessibilityTypesGQL,
    private accessibilityGQL: AccessibilityGQL,
    private difficultyRatingsGQL: DifficultyRatingsGQL,
  ) {

    this.$types = this.typesGQL.watch().valueChanges;
    this.$difficultyRatings = this.difficultyRatingsGQL.watch().valueChanges

    this.$accessibility = combineLatest(
      this.$hidden,
      this.accessibilityGQL.watch().valueChanges,
    ).pipe(map(([hidden,list]) => {
      const newList = JSON.parse(JSON.stringify(list));
      newList.data.entries = newList.data.entries.filter((i: any) => hidden.includes(i.accessibilityType[0].slug))
      return newList;
    }))

  }

  public refresh (delay: number = 0): void {
    if (delay <= 0) {
      console.log('refresh data');
      this.accessibilityGQL.watch().getCurrentResult();
      this.difficultyRatingsGQL.watch().getCurrentResult();
      return;
    }
    console.log('Waiting '+delay+'ms to refresh');
    setTimeout(()=>{
      this.refresh();
    }, delay)
  }

  public findItem (slug: string): Observable<AccessibilityQuery['entries'][0]> {
    return new Observable(subscriber => {
      this.$accessibility.subscribe(result => {
        subscriber.next(result.data.entries.find(x => x.slug === slug));
        subscriber.complete();
      }, error => {
        subscriber.error(error)
      })
    });
  }

}
