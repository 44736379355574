import { Component, OnInit } from '@angular/core';
import {DataService} from "../../services/data.service";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    private location: Location,
    public router: Router,
    public dataService: DataService,
  ) { }

  public goBack():void {
    if (this.dataService.menu.show) {
      this.dataService.menu.toggle(false);
      return;
    }
    this.location.back();
  }

  public print():void {
    window.print();
  }

  public centreGPS(): void {
    this.dataService.map.gpsTracking = true;
    this.dataService.menu.toggle(false);
    this.dataService.map.requestOrientationPermissions();
    this.dataService.map.checkGeoPermission();
    this.dataService.map.updateGPS(this.dataService.map.gpsPositionActual);
    this.dataService.map.centreMap(this.dataService.map.gpsPositionActual, 17);
  }

  public goHome(): void {
    this.dataService.menu.toggle(false);
    this.router.navigate(['/'], {queryParamsHandling: 'preserve', preserveFragment: true})
    setTimeout(()=>{
      this.dataService.map.centreMap(this.dataService.map.centre,17)
    },100)
  }

}
