import {ChangeDetectorRef, Component, HostBinding, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataService, ListData, ListNav} from "../../services/data.service";
import {Observable, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {animate, animateChild, group, query, state, style, transition, trigger} from "@angular/animations";
import {PublicArtworksQuery} from "../../graphql/gql.overrides";
import {AccessibilityQuery} from "../../../generated/graphql";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [
    trigger('anim', [
      transition(':enter', [
        style({transform: 'translateY(100%)', display: 'block'}),
        animate('0.15s linear', style({transform: 'translateY(0%)'}))
      ]) ,
      transition(':leave', [
        style({transform: 'translateY(0%)', display: 'block', 'max-height': '100vh'}),
        animate('0.15s linear', style({transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class ContentComponent implements OnInit, OnDestroy {
  @HostBinding('@anim')

  public $subs: Subscription[] = []
  public $metaContent: Observable<AccessibilityQuery['entries'][0]>;
  public $navData: Observable<ListNav>;
  public routeData: google.maps.DirectionsLeg;
  public contentUrl: SafeResourceUrl;
  public readMore: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public dataService: DataService,
    public http: HttpClient,
    private sanitizer: DomSanitizer,
    public router: Router,
    public cdRef: ChangeDetectorRef,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.$subs.push(
      this.route.params.subscribe(params => {

        this.$metaContent = this.dataService.findItem(params.slug);

        this.dataService.map.setFocusedMarkers([params.slug]);
        this.routeData = null;
        this.readMore = false;

        this.$subs.push(
          this.$metaContent.subscribe((result: any) => {
            console.log(result);
            // this.$navData = this.dataService.getListNav(params.slug)
            // this.contentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.url.replace('http://', 'https://'));
            setTimeout(()=>{
              // @ts-ignore
              this.dataService.map.centreMap(result.position, result.accessibilityType[0].smallIcon ? 18 : 17);
            },200)
            this.$subs.push(this.dataService.map.getRoute(result.position).subscribe(data => {
              this.routeData = data;
              this.cdRef.detectChanges();
            }))
          })
        )
      })
    )
  }

  public startDirections(metaData, showPath = false): void {
    this.ngZone.run(() => {
      this.dataService.map.startDirections(metaData, showPath);
      this.router.navigate(['/'], {queryParamsHandling: 'preserve', preserveFragment: true});
    })
  }

  ngOnDestroy(): void {
    this.$subs.forEach(sub => {
      sub.unsubscribe();
    })
  }

}
