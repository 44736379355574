import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "EntryInterface",
        "possibleTypes": [
          {
            "name": "publicArtwork_publicArtwork_Entry"
          },
          {
            "name": "artWalks_artWalks_Entry"
          },
          {
            "name": "accessibility_difficultyRating_Entry"
          },
          {
            "name": "accessibility_default_Entry"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "AssetInterface",
        "possibleTypes": [
          {
            "name": "files_Asset"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CategoryInterface",
        "possibleTypes": [
          {
            "name": "accessibilityTypes_Category"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ImagerTransformedImageInterface",
        "possibleTypes": [
          {
            "name": "imagerx"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ElementInterface",
        "possibleTypes": [
          {
            "name": "Element"
          },
          {
            "name": "publicArtwork_publicArtwork_Entry"
          },
          {
            "name": "artist_artist_BlockType"
          },
          {
            "name": "body_text_BlockType"
          },
          {
            "name": "body_lineBreak_BlockType"
          },
          {
            "name": "body_image_BlockType"
          },
          {
            "name": "body_textImageCombo_BlockType"
          },
          {
            "name": "body_bannerImage_BlockType"
          },
          {
            "name": "body_gallery_BlockType"
          },
          {
            "name": "body_videoGallery_BlockType"
          },
          {
            "name": "videos_BlockType"
          },
          {
            "name": "body_callout_BlockType"
          },
          {
            "name": "body_map_BlockType"
          },
          {
            "name": "body_faq_BlockType"
          },
          {
            "name": "faq_BlockType"
          },
          {
            "name": "body_media_BlockType"
          },
          {
            "name": "body_audioPlayer_BlockType"
          },
          {
            "name": "body_pageComponent_BlockType"
          },
          {
            "name": "body_table_BlockType"
          },
          {
            "name": "body_fileList_BlockType"
          },
          {
            "name": "body_events_BlockType"
          },
          {
            "name": "body_surveyMonkeySignUp_BlockType"
          },
          {
            "name": "body_socialMediaLinks_BlockType"
          },
          {
            "name": "body_featureList_BlockType"
          },
          {
            "name": "list_BlockType"
          },
          {
            "name": "body_embedCode_BlockType"
          },
          {
            "name": "body_entriesMap_BlockType"
          },
          {
            "name": "body_overviewLinks_BlockType"
          },
          {
            "name": "links_BlockType"
          },
          {
            "name": "body_form_BlockType"
          },
          {
            "name": "artWalks_artWalks_Entry"
          },
          {
            "name": "accessibility_difficultyRating_Entry"
          },
          {
            "name": "accessibility_default_Entry"
          },
          {
            "name": "shortcuts_shortcut_BlockType"
          },
          {
            "name": "files_Asset"
          },
          {
            "name": "User"
          },
          {
            "name": "accessibilityTypes_Category"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "artist_MatrixField",
        "possibleTypes": [
          {
            "name": "artist_artist_BlockType"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "MatrixBlockInterface",
        "possibleTypes": [
          {
            "name": "artist_artist_BlockType"
          },
          {
            "name": "body_text_BlockType"
          },
          {
            "name": "body_lineBreak_BlockType"
          },
          {
            "name": "body_image_BlockType"
          },
          {
            "name": "body_textImageCombo_BlockType"
          },
          {
            "name": "body_bannerImage_BlockType"
          },
          {
            "name": "body_gallery_BlockType"
          },
          {
            "name": "body_videoGallery_BlockType"
          },
          {
            "name": "body_callout_BlockType"
          },
          {
            "name": "body_map_BlockType"
          },
          {
            "name": "body_faq_BlockType"
          },
          {
            "name": "body_media_BlockType"
          },
          {
            "name": "body_audioPlayer_BlockType"
          },
          {
            "name": "body_pageComponent_BlockType"
          },
          {
            "name": "body_table_BlockType"
          },
          {
            "name": "body_fileList_BlockType"
          },
          {
            "name": "body_events_BlockType"
          },
          {
            "name": "body_surveyMonkeySignUp_BlockType"
          },
          {
            "name": "body_socialMediaLinks_BlockType"
          },
          {
            "name": "body_featureList_BlockType"
          },
          {
            "name": "body_embedCode_BlockType"
          },
          {
            "name": "body_entriesMap_BlockType"
          },
          {
            "name": "body_overviewLinks_BlockType"
          },
          {
            "name": "body_form_BlockType"
          },
          {
            "name": "shortcuts_shortcut_BlockType"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "body_MatrixField",
        "possibleTypes": [
          {
            "name": "body_text_BlockType"
          },
          {
            "name": "body_lineBreak_BlockType"
          },
          {
            "name": "body_image_BlockType"
          },
          {
            "name": "body_textImageCombo_BlockType"
          },
          {
            "name": "body_bannerImage_BlockType"
          },
          {
            "name": "body_gallery_BlockType"
          },
          {
            "name": "body_videoGallery_BlockType"
          },
          {
            "name": "body_callout_BlockType"
          },
          {
            "name": "body_map_BlockType"
          },
          {
            "name": "body_faq_BlockType"
          },
          {
            "name": "body_media_BlockType"
          },
          {
            "name": "body_audioPlayer_BlockType"
          },
          {
            "name": "body_pageComponent_BlockType"
          },
          {
            "name": "body_table_BlockType"
          },
          {
            "name": "body_fileList_BlockType"
          },
          {
            "name": "body_events_BlockType"
          },
          {
            "name": "body_surveyMonkeySignUp_BlockType"
          },
          {
            "name": "body_socialMediaLinks_BlockType"
          },
          {
            "name": "body_featureList_BlockType"
          },
          {
            "name": "body_embedCode_BlockType"
          },
          {
            "name": "body_entriesMap_BlockType"
          },
          {
            "name": "body_overviewLinks_BlockType"
          },
          {
            "name": "body_form_BlockType"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "videos_SuperTableField",
        "possibleTypes": [
          {
            "name": "videos_BlockType"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SuperTableBlockInterface",
        "possibleTypes": [
          {
            "name": "videos_BlockType"
          },
          {
            "name": "faq_BlockType"
          },
          {
            "name": "list_BlockType"
          },
          {
            "name": "links_BlockType"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "faq_SuperTableField",
        "possibleTypes": [
          {
            "name": "faq_BlockType"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "list_SuperTableField",
        "possibleTypes": [
          {
            "name": "list_BlockType"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "links_SuperTableField",
        "possibleTypes": [
          {
            "name": "links_BlockType"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "UserInterface",
        "possibleTypes": [
          {
            "name": "User"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "RetourInterface",
        "possibleTypes": [
          {
            "name": "RetourType"
          }
        ]
      }
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `QueryArgument` scalar type represents a value to be using in Craft element queries. It can be an integer, a string, or a boolean value. */
  QueryArgument: any;
  /** The `DateTime` scalar type represents a point in time. */
  DateTime: any;
  /** The `Number` scalar type represents a number that can be a float, an integer or a null value. */
  Number: any;
};



export type Query = {
  __typename?: 'Query';
  ping?: Maybe<Scalars['String']>;
  /** This query is used to query for entries. */
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
  /** This query is used to return the number of entries. */
  entryCount: Scalars['Int'];
  /** This query is used to query for a single entry. */
  entry?: Maybe<EntryInterface>;
  /** This query is used to query for assets. */
  assets?: Maybe<Array<Maybe<AssetInterface>>>;
  /** This query is used to return the number of assets. */
  assetCount: Scalars['Int'];
  /** This query is used to query for a single asset. */
  asset?: Maybe<AssetInterface>;
  /** This query is used to query for categories. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** This query is used to return the number of categories. */
  categoryCount: Scalars['Int'];
  /** This query is used to query for a single category. */
  category?: Maybe<CategoryInterface>;
  /** This query is used to query for Imager X transforms. */
  imagerTransform?: Maybe<Array<Maybe<ImagerTransformedImageInterface>>>;
};


export type QueryEntriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryEntryCountArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryEntryArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAssetsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type QueryAssetCountArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type QueryAssetArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type QueryCategoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type QueryCategoryCountArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type QueryImagerTransformArgs = {
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
};


export type AssetCriteriaInput = {
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the assets’ filenames. */
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the assets’ file kinds. */
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the assets’ image heights. */
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the assets’ image widths. */
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the assets’ file sizes (in bytes). */
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the assets’ files’ last-modified dates. */
  dateModified?: Maybe<Scalars['String']>;
  /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  /** A list of transform handles to preload. */
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
  uploader?: Maybe<Scalars['QueryArgument']>;
};

export type EntryCriteriaInput = {
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: Maybe<Scalars['Boolean']>;
  /** Determines which structure data should be joined into the query. */
  structureId?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on whether the elements have any descendants. */
  hasDescendants?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results to only elements that are ancestors of another element, provided by its ID. */
  ancestorOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element specified by `ancestorOf`. */
  ancestorDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are descendants of another element, provided by its ID. */
  descendantOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element specified by `descendantOf`. */
  descendantDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on whether the elements are “leaves” (element with no descendants). */
  leaves?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results to only the entry that comes immediately after another element, provided by its ID. */
  nextSiblingOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only the entry that comes immediately before another element, provided by its ID. */
  prevSiblingOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only entries that are positioned after another element, provided by its ID. */
  positionedAfter?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only entries that are positioned before another element, provided by its ID. */
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Whether to only return entries that the user has permission to edit. */
  editable?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the section handles the entries belong to. */
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the entries’ entry type handles. */
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the entries’ authors. */
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to. */
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the entries’ post dates. */
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only entries that were posted before a certain date. */
  before?: Maybe<Scalars['String']>;
  /** Narrows the query results to only entries that were posted on or after a certain date. */
  after?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the entries’ expiry dates. */
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserCriteriaInput = {
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the users’ email addresses. */
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the users’ usernames. */
  username?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the users’ first names. */
  firstName?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the users’ last names. */
  lastName?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only users that have (or don’t have) a user photo. */
  hasPhoto?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the user group the users belong to. */
  group?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type CategoryCriteriaInput = {
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: Maybe<Scalars['Boolean']>;
  /** Determines which structure data should be joined into the query. */
  structureId?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on whether the elements have any descendants. */
  hasDescendants?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results to only elements that are ancestors of another element, provided by its ID. */
  ancestorOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element specified by `ancestorOf`. */
  ancestorDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are descendants of another element, provided by its ID. */
  descendantOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element specified by `descendantOf`. */
  descendantDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on whether the elements are “leaves” (element with no descendants). */
  leaves?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results to only the entry that comes immediately after another element, provided by its ID. */
  nextSiblingOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only the entry that comes immediately before another element, provided by its ID. */
  prevSiblingOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only entries that are positioned after another element, provided by its ID. */
  positionedAfter?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only entries that are positioned before another element, provided by its ID. */
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  /** Whether to only return categories that the user has permission to edit. */
  editable?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type TagCriteriaInput = {
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Ether_MapQuery = {
  coordinate?: Maybe<Ether_MapCoords>;
  location?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  unit?: Maybe<Ether_MapUnit>;
};

export type Ether_MapCoords = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum Ether_MapUnit {
  Miles = 'Miles',
  Kilometres = 'Kilometres'
}

/** This is the interface implemented by all entries. */
export type EntryInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns the element’s ID, or if it’s a draft/revision, its source element’s ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the element’s UUID, or if it’s a draft/revision, its source element’s UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The ID of the draft to return (from the `drafts` table) */
  draftId?: Maybe<Scalars['Int']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
};


/** This is the interface implemented by all entries. */
export type EntryInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceDescendantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceAncestorsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfacePrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all assets. */
export type AssetInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>;
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>;
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>;
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>;
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** The file kind. */
  kind?: Maybe<Scalars['String']>;
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>;
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>;
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>;
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset?: Maybe<Scalars['String']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>;
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>;
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<AssetInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<AssetInterface>;
};


/** This is the interface implemented by all assets. */
export type AssetInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceHeightArgs = {
  handle?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interlace?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  immediately?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceWidthArgs = {
  handle?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interlace?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  immediately?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceSrcsetArgs = {
  sizes: Array<Scalars['String']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
  handle?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interlace?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  immediately?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfacePrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfacePrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

/** This is the interface implemented by Imager X. */
export type ImagerTransformedImageInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Path to transformed image. */
  path?: Maybe<Scalars['String']>;
  /** Filename of transformed image. */
  filename?: Maybe<Scalars['String']>;
  /** Extension of transformed image. */
  extension?: Maybe<Scalars['String']>;
  /** URL for transformed image. */
  url?: Maybe<Scalars['String']>;
  /** Mime type of transformed image. */
  mimeType?: Maybe<Scalars['String']>;
  /** Width of transformed image. */
  width?: Maybe<Scalars['Int']>;
  /** Height of transformed image. */
  height?: Maybe<Scalars['Int']>;
  /** Size of transformed image. */
  size?: Maybe<Scalars['Int']>;
  /** Size of transformed image. */
  isNew?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ping?: Maybe<Scalars['String']>;
};

export type Element = ElementInterface & {
  __typename?: 'Element';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
};


export type Element_CountArgs = {
  field: Scalars['String'];
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
};


/** This is the interface implemented by all elements. */
export type ElementInterface_CountArgs = {
  field: Scalars['String'];
};

export type PublicArtwork_PublicArtwork_Entry = ElementInterface & EntryInterface & {
  __typename?: 'publicArtwork_publicArtwork_Entry';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns the element’s ID, or if it’s a draft/revision, its source element’s ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the element’s UUID, or if it’s a draft/revision, its source element’s UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The ID of the draft to return (from the `drafts` table) */
  draftId?: Maybe<Scalars['Int']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  geoLocation?: Maybe<Ether_Map>;
  artist?: Maybe<Array<Maybe<Artist_MatrixField>>>;
  year?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Array<Maybe<AssetInterface>>>;
  summaryRT?: Maybe<Scalars['String']>;
  body?: Maybe<Array<Maybe<Body_MatrixField>>>;
};


export type PublicArtwork_PublicArtwork_Entry_CountArgs = {
  field: Scalars['String'];
};


export type PublicArtwork_PublicArtwork_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryDescendantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryAncestorsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryPrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryArtistArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PublicArtwork_PublicArtwork_EntryFeatureImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type PublicArtwork_PublicArtwork_EntryBodyArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Ether_Map = {
  __typename?: 'Ether_Map';
  /** The maps latitude. */
  lat?: Maybe<Scalars['Float']>;
  /** The maps longitude. */
  lng?: Maybe<Scalars['Float']>;
  /** The maps zoom level. */
  zoom?: Maybe<Scalars['Int']>;
  /** The distance to this location. */
  distance?: Maybe<Scalars['Float']>;
  /** The full address. */
  address?: Maybe<Scalars['String']>;
  /** The maps address parts. */
  parts?: Maybe<Ether_MapParts>;
};

export type Ether_MapParts = {
  __typename?: 'Ether_MapParts';
  /** The address name / number. */
  number?: Maybe<Scalars['String']>;
  /** The street address. */
  address?: Maybe<Scalars['String']>;
  /** The city. */
  city?: Maybe<Scalars['String']>;
  /** The postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** The county. */
  county?: Maybe<Scalars['String']>;
  /** The state. */
  state?: Maybe<Scalars['String']>;
  /** The country. */
  country?: Maybe<Scalars['String']>;
};

export type Artist_MatrixField = Artist_Artist_BlockType;

export type Artist_Artist_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'artist_artist_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  artist?: Maybe<Scalars['String']>;
};


export type Artist_Artist_BlockType_CountArgs = {
  field: Scalars['String'];
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
};


/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface_CountArgs = {
  field: Scalars['String'];
};

export type Body_MatrixField = Body_Text_BlockType | Body_LineBreak_BlockType | Body_Image_BlockType | Body_TextImageCombo_BlockType | Body_BannerImage_BlockType | Body_Gallery_BlockType | Body_VideoGallery_BlockType | Body_Callout_BlockType | Body_Map_BlockType | Body_Faq_BlockType | Body_Media_BlockType | Body_AudioPlayer_BlockType | Body_PageComponent_BlockType | Body_Table_BlockType | Body_FileList_BlockType | Body_Events_BlockType | Body_SurveyMonkeySignUp_BlockType | Body_SocialMediaLinks_BlockType | Body_FeatureList_BlockType | Body_EmbedCode_BlockType | Body_EntriesMap_BlockType | Body_OverviewLinks_BlockType | Body_Form_BlockType;

export type Body_Text_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_text_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['String']>;
  columnsRuleColour?: Maybe<Scalars['String']>;
};


export type Body_Text_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Text_BlockTypeColumnsArgs = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_Text_BlockTypeColumnsRuleColourArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_LineBreak_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_lineBreak_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  lineThickness?: Maybe<Scalars['Number']>;
  colour?: Maybe<Scalars['String']>;
};


export type Body_LineBreak_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_LineBreak_BlockTypeColourArgs = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_Image_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_image_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  position?: Maybe<Scalars['String']>;
  captionText?: Maybe<Scalars['String']>;
  customLink?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  hideZoomIcon?: Maybe<Scalars['Boolean']>;
  showAsACircle?: Maybe<Scalars['Boolean']>;
};


export type Body_Image_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Image_BlockTypeImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type Body_Image_BlockTypeSizeArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_TextImageCombo_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_textImageCombo_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  imagePosition?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  button?: Maybe<Scalars['String']>;
};


export type Body_TextImageCombo_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_TextImageCombo_BlockTypeImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type Body_TextImageCombo_BlockTypeColourArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_BannerImage_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_bannerImage_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  size?: Maybe<Scalars['String']>;
};


export type Body_BannerImage_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_BannerImage_BlockTypeImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type Body_BannerImage_BlockTypeSizeArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_Gallery_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_gallery_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<AssetInterface>>>;
  displayType?: Maybe<Scalars['String']>;
};


export type Body_Gallery_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Gallery_BlockTypeImagesArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type Body_Gallery_BlockTypeDisplayTypeArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_VideoGallery_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_videoGallery_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Maybe<Videos_SuperTableField>>>;
};


export type Body_VideoGallery_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_VideoGallery_BlockTypeVideosArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Videos_SuperTableField = Videos_BlockType;

export type Videos_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'videos_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['String']>;
};


export type Videos_BlockType_CountArgs = {
  field: Scalars['String'];
};

/** This is the interface implemented by all Super Table blocks. */
export type SuperTableBlockInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
};


/** This is the interface implemented by all Super Table blocks. */
export type SuperTableBlockInterface_CountArgs = {
  field: Scalars['String'];
};

export type Body_Callout_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_callout_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  background?: Maybe<Scalars['String']>;
  sizing?: Maybe<Scalars['String']>;
  button?: Maybe<Scalars['String']>;
};


export type Body_Callout_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Callout_BlockTypeBackgroundArgs = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_Callout_BlockTypeSizingArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_Map_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_map_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  mapType?: Maybe<Scalars['String']>;
  zoomLevel?: Maybe<Scalars['String']>;
};


export type Body_Map_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Map_BlockTypeMapTypeArgs = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_Map_BlockTypeZoomLevelArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Body_Faq_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_faq_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  faq?: Maybe<Array<Maybe<Faq_SuperTableField>>>;
};


export type Body_Faq_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Faq_BlockTypeFaqArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Faq_SuperTableField = Faq_BlockType;

export type Faq_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'faq_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};


export type Faq_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_Media_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_media_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  mediaUrl?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};


export type Body_Media_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_AudioPlayer_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_audioPlayer_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  audioFile?: Maybe<Array<Maybe<AssetInterface>>>;
  position?: Maybe<Scalars['String']>;
};


export type Body_AudioPlayer_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_AudioPlayer_BlockTypeAudioFileArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};

export type Body_PageComponent_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_pageComponent_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  templateName?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
};


export type Body_PageComponent_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_PageComponent_BlockTypeEntriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Body_Table_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_table_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  table?: Maybe<Scalars['String']>;
};


export type Body_Table_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_FileList_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_fileList_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  files?: Maybe<Array<Maybe<AssetInterface>>>;
};


export type Body_FileList_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_FileList_BlockTypeFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};

export type Body_Events_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_events_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  eventsCategory?: Maybe<Array<Maybe<CategoryInterface>>>;
};


export type Body_Events_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_Events_BlockTypeEventsCategoryArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Body_SurveyMonkeySignUp_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_surveyMonkeySignUp_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  surveyId?: Maybe<Scalars['Number']>;
  position?: Maybe<Scalars['String']>;
};


export type Body_SurveyMonkeySignUp_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_SocialMediaLinks_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_socialMediaLinks_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['String']>;
};


export type Body_SocialMediaLinks_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_FeatureList_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_featureList_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<List_SuperTableField>>>;
  listStyle?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  style1?: Maybe<Scalars['String']>;
  style2?: Maybe<Scalars['String']>;
};


export type Body_FeatureList_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_FeatureList_BlockTypeListArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Body_FeatureList_BlockTypeListStyleArgs = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_FeatureList_BlockTypeColourArgs = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_FeatureList_BlockTypeStyle1Args = {
  label?: Maybe<Scalars['Boolean']>;
};


export type Body_FeatureList_BlockTypeStyle2Args = {
  label?: Maybe<Scalars['Boolean']>;
};

export type List_SuperTableField = List_BlockType;

export type List_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'list_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  listItem?: Maybe<Scalars['String']>;
};


export type List_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_EmbedCode_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_embedCode_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};


export type Body_EmbedCode_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_EntriesMap_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_entriesMap_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  mapSection?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Number']>;
};


export type Body_EntriesMap_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Body_OverviewLinks_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_overviewLinks_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  links?: Maybe<Array<Maybe<Links_SuperTableField>>>;
};


export type Body_OverviewLinks_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Body_OverviewLinks_BlockTypeLinksArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Links_SuperTableField = Links_BlockType;

export type Links_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'links_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  linkTo?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type Links_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Links_BlockTypeImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};

export type Body_Form_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'body_form_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  form?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};


export type Body_Form_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ArtWalks_ArtWalks_Entry = ElementInterface & EntryInterface & {
  __typename?: 'artWalks_artWalks_Entry';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns the element’s ID, or if it’s a draft/revision, its source element’s ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the element’s UUID, or if it’s a draft/revision, its source element’s UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The ID of the draft to return (from the `drafts` table) */
  draftId?: Maybe<Scalars['Int']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  summaryRT?: Maybe<Scalars['String']>;
  artworks?: Maybe<Array<Maybe<EntryInterface>>>;
};


export type ArtWalks_ArtWalks_Entry_CountArgs = {
  field: Scalars['String'];
};


export type ArtWalks_ArtWalks_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryDescendantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryAncestorsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryPrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ArtWalks_ArtWalks_EntryArtworksArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Accessibility_DifficultyRating_Entry = ElementInterface & EntryInterface & {
  __typename?: 'accessibility_difficultyRating_Entry';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns the element’s ID, or if it’s a draft/revision, its source element’s ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the element’s UUID, or if it’s a draft/revision, its source element’s UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The ID of the draft to return (from the `drafts` table) */
  draftId?: Maybe<Scalars['Int']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  difficultyRating?: Maybe<Scalars['String']>;
  geoLocation?: Maybe<Ether_Map>;
  geoLocationEnd?: Maybe<Ether_Map>;
};


export type Accessibility_DifficultyRating_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Accessibility_DifficultyRating_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryDescendantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryAncestorsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryPrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_DifficultyRating_EntryDifficultyRatingArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type Accessibility_Default_Entry = ElementInterface & EntryInterface & {
  __typename?: 'accessibility_default_Entry';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns the element’s ID, or if it’s a draft/revision, its source element’s ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the element’s UUID, or if it’s a draft/revision, its source element’s UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The ID of the draft to return (from the `drafts` table) */
  draftId?: Maybe<Scalars['Int']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  accessibilityType?: Maybe<Array<Maybe<CategoryInterface>>>;
  geoLocation?: Maybe<Ether_Map>;
};


export type Accessibility_Default_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Accessibility_Default_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryDescendantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryAncestorsArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryPrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  geoLocation?: Maybe<Ether_MapQuery>;
  year?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  medium?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  featureImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  summaryRT?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  artworks?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  difficultyRating?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  geoLocationEnd?: Maybe<Ether_MapQuery>;
  accessibilityType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  editable?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Accessibility_Default_EntryAccessibilityTypeArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Shortcuts_Shortcut_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'shortcuts_shortcut_BlockType';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
};


export type Shortcuts_Shortcut_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Files_Asset = ElementInterface & AssetInterface & {
  __typename?: 'files_Asset';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>;
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>;
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>;
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>;
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** The file kind. */
  kind?: Maybe<Scalars['String']>;
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>;
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>;
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>;
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset?: Maybe<Scalars['String']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>;
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>;
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<AssetInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<AssetInterface>;
};


export type Files_Asset_CountArgs = {
  field: Scalars['String'];
};


export type Files_AssetHeightArgs = {
  handle?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interlace?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  immediately?: Maybe<Scalars['Boolean']>;
};


export type Files_AssetWidthArgs = {
  handle?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interlace?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  immediately?: Maybe<Scalars['Boolean']>;
};


export type Files_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']>;
};


export type Files_AssetUrlArgs = {
  handle?: Maybe<Scalars['String']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  interlace?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  immediately?: Maybe<Scalars['Boolean']>;
};


export type Files_AssetPrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type Files_AssetNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};

export type User = ElementInterface & UserInterface & {
  __typename?: 'User';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The user's first name or username. */
  friendlyName?: Maybe<Scalars['String']>;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The user's full name or username. */
  name?: Maybe<Scalars['String']>;
  /** The user’s preferences. */
  preferences?: Maybe<Scalars['String']>;
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']>;
  /** The username. */
  username?: Maybe<Scalars['String']>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The user's email. */
  email?: Maybe<Scalars['String']>;
};


export type User_CountArgs = {
  field: Scalars['String'];
};

/** This is the interface implemented by all users. */
export type UserInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The user's first name or username. */
  friendlyName?: Maybe<Scalars['String']>;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The user's full name or username. */
  name?: Maybe<Scalars['String']>;
  /** The user’s preferences. */
  preferences?: Maybe<Scalars['String']>;
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']>;
  /** The username. */
  username?: Maybe<Scalars['String']>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The user's email. */
  email?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all users. */
export type UserInterface_CountArgs = {
  field: Scalars['String'];
};

export type AccessibilityTypes_Category = ElementInterface & CategoryInterface & {
  __typename?: 'accessibilityTypes_Category';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  hex?: Maybe<Scalars['String']>;
  menuIcon?: Maybe<Array<Maybe<AssetInterface>>>;
  mapIcon?: Maybe<Array<Maybe<AssetInterface>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
};


export type AccessibilityTypes_Category_CountArgs = {
  field: Scalars['String'];
};


export type AccessibilityTypes_CategoryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type AccessibilityTypes_CategoryParentArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type AccessibilityTypes_CategoryLocalizedArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type AccessibilityTypes_CategoryPrevArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type AccessibilityTypes_CategoryNextArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  structureId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  descendantDist?: Maybe<Scalars['Int']>;
  leaves?: Maybe<Scalars['Boolean']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  hex?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  menuIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  mapIcon?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  smallIcon?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type AccessibilityTypes_CategoryMenuIconArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};


export type AccessibilityTypes_CategoryMapIconArgs = {
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  inReverse?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploader?: Maybe<Scalars['QueryArgument']>;
};

/** This entity has all the Retour fields */
export type RetourType = RetourInterface & {
  __typename?: 'RetourType';
  /** The id of the redirect. */
  id?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The siteId of the redirect (0 or null for all sites). */
  siteId?: Maybe<Scalars['Int']>;
  /** The id of the Element associated with this redirect (unused/vestigial). */
  associatedElementId?: Maybe<Scalars['Int']>;
  /** Whether the redirect is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The unparsed URL pattern that Retour should match. */
  redirectSrcUrl?: Maybe<Scalars['String']>;
  /** The parsed URL pattern that Retour should match. */
  redirectSrcUrlParsed?: Maybe<Scalars['String']>;
  /** Should the legacy URL be matched by path or by full URL? */
  redirectSrcMatch?: Maybe<Scalars['String']>;
  /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
  redirectMatchType?: Maybe<Scalars['String']>;
  /** The URL that should be redirected to. */
  redirectDestUrl?: Maybe<Scalars['String']>;
  /** The http status code that should be used for the redirect. */
  redirectHttpCode?: Maybe<Scalars['Int']>;
  /** The number of times this redirect has been hit. */
  hitCount?: Maybe<Scalars['Int']>;
  /** A datetime string of when this redirect was last hit. */
  hitLastTime?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by Retour. */
export type RetourInterface = {
  /** The id of the redirect. */
  id?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The siteId of the redirect (0 or null for all sites). */
  siteId?: Maybe<Scalars['Int']>;
  /** The id of the Element associated with this redirect (unused/vestigial). */
  associatedElementId?: Maybe<Scalars['Int']>;
  /** Whether the redirect is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The unparsed URL pattern that Retour should match. */
  redirectSrcUrl?: Maybe<Scalars['String']>;
  /** The parsed URL pattern that Retour should match. */
  redirectSrcUrlParsed?: Maybe<Scalars['String']>;
  /** Should the legacy URL be matched by path or by full URL? */
  redirectSrcMatch?: Maybe<Scalars['String']>;
  /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
  redirectMatchType?: Maybe<Scalars['String']>;
  /** The URL that should be redirected to. */
  redirectDestUrl?: Maybe<Scalars['String']>;
  /** The http status code that should be used for the redirect. */
  redirectHttpCode?: Maybe<Scalars['Int']>;
  /** The number of times this redirect has been hit. */
  hitCount?: Maybe<Scalars['Int']>;
  /** A datetime string of when this redirect was last hit. */
  hitLastTime?: Maybe<Scalars['String']>;
};

/** This entity has all the Imager X transform image interface fields. */
export type Imagerx = ImagerTransformedImageInterface & {
  __typename?: 'imagerx';
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** Path to transformed image. */
  path?: Maybe<Scalars['String']>;
  /** Filename of transformed image. */
  filename?: Maybe<Scalars['String']>;
  /** Extension of transformed image. */
  extension?: Maybe<Scalars['String']>;
  /** URL for transformed image. */
  url?: Maybe<Scalars['String']>;
  /** Mime type of transformed image. */
  mimeType?: Maybe<Scalars['String']>;
  /** Width of transformed image. */
  width?: Maybe<Scalars['Int']>;
  /** Height of transformed image. */
  height?: Maybe<Scalars['Int']>;
  /** Size of transformed image. */
  size?: Maybe<Scalars['Int']>;
  /** Size of transformed image. */
  isNew?: Maybe<Scalars['Boolean']>;
};

export type AccessibilityTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessibilityTypesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<Maybe<(
    { __typename?: 'accessibilityTypes_Category' }
    & Pick<AccessibilityTypes_Category, 'title' | 'slug' | 'hex' | 'smallIcon'>
    & { mapIcon?: Maybe<Array<Maybe<(
      { __typename?: 'files_Asset' }
      & { icon: Files_Asset['url'] }
    )>>>, menuIcon?: Maybe<Array<Maybe<(
      { __typename?: 'files_Asset' }
      & { icon: Files_Asset['url'] }
    )>>> }
  )>>> }
);

export type AccessibilityQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessibilityQuery = (
  { __typename?: 'Query' }
  & { entries?: Maybe<Array<Maybe<(
    { __typename?: 'publicArtwork_publicArtwork_Entry' }
    & Pick<PublicArtwork_PublicArtwork_Entry, 'id' | 'title' | 'slug'>
  ) | (
    { __typename?: 'artWalks_artWalks_Entry' }
    & Pick<ArtWalks_ArtWalks_Entry, 'id' | 'title' | 'slug'>
  ) | (
    { __typename?: 'accessibility_difficultyRating_Entry' }
    & Pick<Accessibility_DifficultyRating_Entry, 'id' | 'title' | 'slug'>
  ) | (
    { __typename?: 'accessibility_default_Entry' }
    & Pick<Accessibility_Default_Entry, 'id' | 'title' | 'slug'>
    & { accessibilityType?: Maybe<Array<Maybe<(
      { __typename?: 'accessibilityTypes_Category' }
      & Pick<AccessibilityTypes_Category, 'title' | 'slug' | 'hex' | 'smallIcon'>
      & { mapIcon?: Maybe<Array<Maybe<(
        { __typename?: 'files_Asset' }
        & { icon: Files_Asset['url'] }
      )>>>, menuIcon?: Maybe<Array<Maybe<(
        { __typename?: 'files_Asset' }
        & { icon: Files_Asset['url'] }
      )>>> }
    )>>>, position?: Maybe<(
      { __typename?: 'Ether_Map' }
      & Pick<Ether_Map, 'lat' | 'lng'>
    )> }
  )>>> }
);

export type DifficultyRatingsQueryVariables = Exact<{ [key: string]: never; }>;


export type DifficultyRatingsQuery = (
  { __typename?: 'Query' }
  & { entries?: Maybe<Array<Maybe<(
    { __typename?: 'publicArtwork_publicArtwork_Entry' }
    & Pick<PublicArtwork_PublicArtwork_Entry, 'id' | 'title' | 'slug'>
  ) | (
    { __typename?: 'artWalks_artWalks_Entry' }
    & Pick<ArtWalks_ArtWalks_Entry, 'id' | 'title' | 'slug'>
  ) | (
    { __typename?: 'accessibility_difficultyRating_Entry' }
    & Pick<Accessibility_DifficultyRating_Entry, 'difficultyRating' | 'id' | 'title' | 'slug'>
    & { position?: Maybe<(
      { __typename?: 'Ether_Map' }
      & Pick<Ether_Map, 'lat' | 'lng'>
    )>, position2?: Maybe<(
      { __typename?: 'Ether_Map' }
      & Pick<Ether_Map, 'lat' | 'lng'>
    )> }
  ) | (
    { __typename?: 'accessibility_default_Entry' }
    & Pick<Accessibility_Default_Entry, 'id' | 'title' | 'slug'>
  )>>> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (obj: T, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  QueryArgument: ResolverTypeWrapper<Scalars['QueryArgument']>;
  AssetCriteriaInput: AssetCriteriaInput;
  EntryCriteriaInput: EntryCriteriaInput;
  UserCriteriaInput: UserCriteriaInput;
  CategoryCriteriaInput: CategoryCriteriaInput;
  TagCriteriaInput: TagCriteriaInput;
  Ether_MapQuery: Ether_MapQuery;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Ether_MapCoords: Ether_MapCoords;
  Ether_MapUnit: Ether_MapUnit;
  EntryInterface: ResolversTypes['publicArtwork_publicArtwork_Entry'] | ResolversTypes['artWalks_artWalks_Entry'] | ResolversTypes['accessibility_difficultyRating_Entry'] | ResolversTypes['accessibility_default_Entry'];
  ID: ResolverTypeWrapper<Scalars['ID']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  AssetInterface: ResolversTypes['files_Asset'];
  CategoryInterface: ResolversTypes['accessibilityTypes_Category'];
  ImagerTransformedImageInterface: ResolversTypes['imagerx'];
  Mutation: ResolverTypeWrapper<{}>;
  Element: ResolverTypeWrapper<Element>;
  ElementInterface: ResolversTypes['Element'] | ResolversTypes['publicArtwork_publicArtwork_Entry'] | ResolversTypes['artist_artist_BlockType'] | ResolversTypes['body_text_BlockType'] | ResolversTypes['body_lineBreak_BlockType'] | ResolversTypes['body_image_BlockType'] | ResolversTypes['body_textImageCombo_BlockType'] | ResolversTypes['body_bannerImage_BlockType'] | ResolversTypes['body_gallery_BlockType'] | ResolversTypes['body_videoGallery_BlockType'] | ResolversTypes['videos_BlockType'] | ResolversTypes['body_callout_BlockType'] | ResolversTypes['body_map_BlockType'] | ResolversTypes['body_faq_BlockType'] | ResolversTypes['faq_BlockType'] | ResolversTypes['body_media_BlockType'] | ResolversTypes['body_audioPlayer_BlockType'] | ResolversTypes['body_pageComponent_BlockType'] | ResolversTypes['body_table_BlockType'] | ResolversTypes['body_fileList_BlockType'] | ResolversTypes['body_events_BlockType'] | ResolversTypes['body_surveyMonkeySignUp_BlockType'] | ResolversTypes['body_socialMediaLinks_BlockType'] | ResolversTypes['body_featureList_BlockType'] | ResolversTypes['list_BlockType'] | ResolversTypes['body_embedCode_BlockType'] | ResolversTypes['body_entriesMap_BlockType'] | ResolversTypes['body_overviewLinks_BlockType'] | ResolversTypes['links_BlockType'] | ResolversTypes['body_form_BlockType'] | ResolversTypes['artWalks_artWalks_Entry'] | ResolversTypes['accessibility_difficultyRating_Entry'] | ResolversTypes['accessibility_default_Entry'] | ResolversTypes['shortcuts_shortcut_BlockType'] | ResolversTypes['files_Asset'] | ResolversTypes['User'] | ResolversTypes['accessibilityTypes_Category'];
  publicArtwork_publicArtwork_Entry: ResolverTypeWrapper<Omit<PublicArtwork_PublicArtwork_Entry, 'artist' | 'body'> & { artist?: Maybe<Array<Maybe<ResolversTypes['artist_MatrixField']>>>, body?: Maybe<Array<Maybe<ResolversTypes['body_MatrixField']>>> }>;
  Ether_Map: ResolverTypeWrapper<Ether_Map>;
  Ether_MapParts: ResolverTypeWrapper<Ether_MapParts>;
  artist_MatrixField: ResolversTypes['artist_artist_BlockType'];
  artist_artist_BlockType: ResolverTypeWrapper<Artist_Artist_BlockType>;
  MatrixBlockInterface: ResolversTypes['artist_artist_BlockType'] | ResolversTypes['body_text_BlockType'] | ResolversTypes['body_lineBreak_BlockType'] | ResolversTypes['body_image_BlockType'] | ResolversTypes['body_textImageCombo_BlockType'] | ResolversTypes['body_bannerImage_BlockType'] | ResolversTypes['body_gallery_BlockType'] | ResolversTypes['body_videoGallery_BlockType'] | ResolversTypes['body_callout_BlockType'] | ResolversTypes['body_map_BlockType'] | ResolversTypes['body_faq_BlockType'] | ResolversTypes['body_media_BlockType'] | ResolversTypes['body_audioPlayer_BlockType'] | ResolversTypes['body_pageComponent_BlockType'] | ResolversTypes['body_table_BlockType'] | ResolversTypes['body_fileList_BlockType'] | ResolversTypes['body_events_BlockType'] | ResolversTypes['body_surveyMonkeySignUp_BlockType'] | ResolversTypes['body_socialMediaLinks_BlockType'] | ResolversTypes['body_featureList_BlockType'] | ResolversTypes['body_embedCode_BlockType'] | ResolversTypes['body_entriesMap_BlockType'] | ResolversTypes['body_overviewLinks_BlockType'] | ResolversTypes['body_form_BlockType'] | ResolversTypes['shortcuts_shortcut_BlockType'];
  body_MatrixField: ResolversTypes['body_text_BlockType'] | ResolversTypes['body_lineBreak_BlockType'] | ResolversTypes['body_image_BlockType'] | ResolversTypes['body_textImageCombo_BlockType'] | ResolversTypes['body_bannerImage_BlockType'] | ResolversTypes['body_gallery_BlockType'] | ResolversTypes['body_videoGallery_BlockType'] | ResolversTypes['body_callout_BlockType'] | ResolversTypes['body_map_BlockType'] | ResolversTypes['body_faq_BlockType'] | ResolversTypes['body_media_BlockType'] | ResolversTypes['body_audioPlayer_BlockType'] | ResolversTypes['body_pageComponent_BlockType'] | ResolversTypes['body_table_BlockType'] | ResolversTypes['body_fileList_BlockType'] | ResolversTypes['body_events_BlockType'] | ResolversTypes['body_surveyMonkeySignUp_BlockType'] | ResolversTypes['body_socialMediaLinks_BlockType'] | ResolversTypes['body_featureList_BlockType'] | ResolversTypes['body_embedCode_BlockType'] | ResolversTypes['body_entriesMap_BlockType'] | ResolversTypes['body_overviewLinks_BlockType'] | ResolversTypes['body_form_BlockType'];
  body_text_BlockType: ResolverTypeWrapper<Body_Text_BlockType>;
  body_lineBreak_BlockType: ResolverTypeWrapper<Body_LineBreak_BlockType>;
  Number: ResolverTypeWrapper<Scalars['Number']>;
  body_image_BlockType: ResolverTypeWrapper<Body_Image_BlockType>;
  body_textImageCombo_BlockType: ResolverTypeWrapper<Body_TextImageCombo_BlockType>;
  body_bannerImage_BlockType: ResolverTypeWrapper<Body_BannerImage_BlockType>;
  body_gallery_BlockType: ResolverTypeWrapper<Body_Gallery_BlockType>;
  body_videoGallery_BlockType: ResolverTypeWrapper<Omit<Body_VideoGallery_BlockType, 'videos'> & { videos?: Maybe<Array<Maybe<ResolversTypes['videos_SuperTableField']>>> }>;
  videos_SuperTableField: ResolversTypes['videos_BlockType'];
  videos_BlockType: ResolverTypeWrapper<Videos_BlockType>;
  SuperTableBlockInterface: ResolversTypes['videos_BlockType'] | ResolversTypes['faq_BlockType'] | ResolversTypes['list_BlockType'] | ResolversTypes['links_BlockType'];
  body_callout_BlockType: ResolverTypeWrapper<Body_Callout_BlockType>;
  body_map_BlockType: ResolverTypeWrapper<Body_Map_BlockType>;
  body_faq_BlockType: ResolverTypeWrapper<Omit<Body_Faq_BlockType, 'faq'> & { faq?: Maybe<Array<Maybe<ResolversTypes['faq_SuperTableField']>>> }>;
  faq_SuperTableField: ResolversTypes['faq_BlockType'];
  faq_BlockType: ResolverTypeWrapper<Faq_BlockType>;
  body_media_BlockType: ResolverTypeWrapper<Body_Media_BlockType>;
  body_audioPlayer_BlockType: ResolverTypeWrapper<Body_AudioPlayer_BlockType>;
  body_pageComponent_BlockType: ResolverTypeWrapper<Body_PageComponent_BlockType>;
  body_table_BlockType: ResolverTypeWrapper<Body_Table_BlockType>;
  body_fileList_BlockType: ResolverTypeWrapper<Body_FileList_BlockType>;
  body_events_BlockType: ResolverTypeWrapper<Body_Events_BlockType>;
  body_surveyMonkeySignUp_BlockType: ResolverTypeWrapper<Body_SurveyMonkeySignUp_BlockType>;
  body_socialMediaLinks_BlockType: ResolverTypeWrapper<Body_SocialMediaLinks_BlockType>;
  body_featureList_BlockType: ResolverTypeWrapper<Omit<Body_FeatureList_BlockType, 'list'> & { list?: Maybe<Array<Maybe<ResolversTypes['list_SuperTableField']>>> }>;
  list_SuperTableField: ResolversTypes['list_BlockType'];
  list_BlockType: ResolverTypeWrapper<List_BlockType>;
  body_embedCode_BlockType: ResolverTypeWrapper<Body_EmbedCode_BlockType>;
  body_entriesMap_BlockType: ResolverTypeWrapper<Body_EntriesMap_BlockType>;
  body_overviewLinks_BlockType: ResolverTypeWrapper<Omit<Body_OverviewLinks_BlockType, 'links'> & { links?: Maybe<Array<Maybe<ResolversTypes['links_SuperTableField']>>> }>;
  links_SuperTableField: ResolversTypes['links_BlockType'];
  links_BlockType: ResolverTypeWrapper<Links_BlockType>;
  body_form_BlockType: ResolverTypeWrapper<Body_Form_BlockType>;
  artWalks_artWalks_Entry: ResolverTypeWrapper<ArtWalks_ArtWalks_Entry>;
  accessibility_difficultyRating_Entry: ResolverTypeWrapper<Accessibility_DifficultyRating_Entry>;
  accessibility_default_Entry: ResolverTypeWrapper<Accessibility_Default_Entry>;
  shortcuts_shortcut_BlockType: ResolverTypeWrapper<Shortcuts_Shortcut_BlockType>;
  files_Asset: ResolverTypeWrapper<Files_Asset>;
  User: ResolverTypeWrapper<User>;
  UserInterface: ResolversTypes['User'];
  accessibilityTypes_Category: ResolverTypeWrapper<AccessibilityTypes_Category>;
  RetourType: ResolverTypeWrapper<RetourType>;
  RetourInterface: ResolversTypes['RetourType'];
  imagerx: ResolverTypeWrapper<Imagerx>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  Int: Scalars['Int'];
  QueryArgument: Scalars['QueryArgument'];
  AssetCriteriaInput: AssetCriteriaInput;
  EntryCriteriaInput: EntryCriteriaInput;
  UserCriteriaInput: UserCriteriaInput;
  CategoryCriteriaInput: CategoryCriteriaInput;
  TagCriteriaInput: TagCriteriaInput;
  Ether_MapQuery: Ether_MapQuery;
  Float: Scalars['Float'];
  Ether_MapCoords: Ether_MapCoords;
  EntryInterface: ResolversParentTypes['publicArtwork_publicArtwork_Entry'] | ResolversParentTypes['artWalks_artWalks_Entry'] | ResolversParentTypes['accessibility_difficultyRating_Entry'] | ResolversParentTypes['accessibility_default_Entry'];
  ID: Scalars['ID'];
  DateTime: Scalars['DateTime'];
  AssetInterface: ResolversParentTypes['files_Asset'];
  CategoryInterface: ResolversParentTypes['accessibilityTypes_Category'];
  ImagerTransformedImageInterface: ResolversParentTypes['imagerx'];
  Mutation: {};
  Element: Element;
  ElementInterface: ResolversParentTypes['Element'] | ResolversParentTypes['publicArtwork_publicArtwork_Entry'] | ResolversParentTypes['artist_artist_BlockType'] | ResolversParentTypes['body_text_BlockType'] | ResolversParentTypes['body_lineBreak_BlockType'] | ResolversParentTypes['body_image_BlockType'] | ResolversParentTypes['body_textImageCombo_BlockType'] | ResolversParentTypes['body_bannerImage_BlockType'] | ResolversParentTypes['body_gallery_BlockType'] | ResolversParentTypes['body_videoGallery_BlockType'] | ResolversParentTypes['videos_BlockType'] | ResolversParentTypes['body_callout_BlockType'] | ResolversParentTypes['body_map_BlockType'] | ResolversParentTypes['body_faq_BlockType'] | ResolversParentTypes['faq_BlockType'] | ResolversParentTypes['body_media_BlockType'] | ResolversParentTypes['body_audioPlayer_BlockType'] | ResolversParentTypes['body_pageComponent_BlockType'] | ResolversParentTypes['body_table_BlockType'] | ResolversParentTypes['body_fileList_BlockType'] | ResolversParentTypes['body_events_BlockType'] | ResolversParentTypes['body_surveyMonkeySignUp_BlockType'] | ResolversParentTypes['body_socialMediaLinks_BlockType'] | ResolversParentTypes['body_featureList_BlockType'] | ResolversParentTypes['list_BlockType'] | ResolversParentTypes['body_embedCode_BlockType'] | ResolversParentTypes['body_entriesMap_BlockType'] | ResolversParentTypes['body_overviewLinks_BlockType'] | ResolversParentTypes['links_BlockType'] | ResolversParentTypes['body_form_BlockType'] | ResolversParentTypes['artWalks_artWalks_Entry'] | ResolversParentTypes['accessibility_difficultyRating_Entry'] | ResolversParentTypes['accessibility_default_Entry'] | ResolversParentTypes['shortcuts_shortcut_BlockType'] | ResolversParentTypes['files_Asset'] | ResolversParentTypes['User'] | ResolversParentTypes['accessibilityTypes_Category'];
  publicArtwork_publicArtwork_Entry: Omit<PublicArtwork_PublicArtwork_Entry, 'artist' | 'body'> & { artist?: Maybe<Array<Maybe<ResolversParentTypes['artist_MatrixField']>>>, body?: Maybe<Array<Maybe<ResolversParentTypes['body_MatrixField']>>> };
  Ether_Map: Ether_Map;
  Ether_MapParts: Ether_MapParts;
  artist_MatrixField: ResolversParentTypes['artist_artist_BlockType'];
  artist_artist_BlockType: Artist_Artist_BlockType;
  MatrixBlockInterface: ResolversParentTypes['artist_artist_BlockType'] | ResolversParentTypes['body_text_BlockType'] | ResolversParentTypes['body_lineBreak_BlockType'] | ResolversParentTypes['body_image_BlockType'] | ResolversParentTypes['body_textImageCombo_BlockType'] | ResolversParentTypes['body_bannerImage_BlockType'] | ResolversParentTypes['body_gallery_BlockType'] | ResolversParentTypes['body_videoGallery_BlockType'] | ResolversParentTypes['body_callout_BlockType'] | ResolversParentTypes['body_map_BlockType'] | ResolversParentTypes['body_faq_BlockType'] | ResolversParentTypes['body_media_BlockType'] | ResolversParentTypes['body_audioPlayer_BlockType'] | ResolversParentTypes['body_pageComponent_BlockType'] | ResolversParentTypes['body_table_BlockType'] | ResolversParentTypes['body_fileList_BlockType'] | ResolversParentTypes['body_events_BlockType'] | ResolversParentTypes['body_surveyMonkeySignUp_BlockType'] | ResolversParentTypes['body_socialMediaLinks_BlockType'] | ResolversParentTypes['body_featureList_BlockType'] | ResolversParentTypes['body_embedCode_BlockType'] | ResolversParentTypes['body_entriesMap_BlockType'] | ResolversParentTypes['body_overviewLinks_BlockType'] | ResolversParentTypes['body_form_BlockType'] | ResolversParentTypes['shortcuts_shortcut_BlockType'];
  body_MatrixField: ResolversParentTypes['body_text_BlockType'] | ResolversParentTypes['body_lineBreak_BlockType'] | ResolversParentTypes['body_image_BlockType'] | ResolversParentTypes['body_textImageCombo_BlockType'] | ResolversParentTypes['body_bannerImage_BlockType'] | ResolversParentTypes['body_gallery_BlockType'] | ResolversParentTypes['body_videoGallery_BlockType'] | ResolversParentTypes['body_callout_BlockType'] | ResolversParentTypes['body_map_BlockType'] | ResolversParentTypes['body_faq_BlockType'] | ResolversParentTypes['body_media_BlockType'] | ResolversParentTypes['body_audioPlayer_BlockType'] | ResolversParentTypes['body_pageComponent_BlockType'] | ResolversParentTypes['body_table_BlockType'] | ResolversParentTypes['body_fileList_BlockType'] | ResolversParentTypes['body_events_BlockType'] | ResolversParentTypes['body_surveyMonkeySignUp_BlockType'] | ResolversParentTypes['body_socialMediaLinks_BlockType'] | ResolversParentTypes['body_featureList_BlockType'] | ResolversParentTypes['body_embedCode_BlockType'] | ResolversParentTypes['body_entriesMap_BlockType'] | ResolversParentTypes['body_overviewLinks_BlockType'] | ResolversParentTypes['body_form_BlockType'];
  body_text_BlockType: Body_Text_BlockType;
  body_lineBreak_BlockType: Body_LineBreak_BlockType;
  Number: Scalars['Number'];
  body_image_BlockType: Body_Image_BlockType;
  body_textImageCombo_BlockType: Body_TextImageCombo_BlockType;
  body_bannerImage_BlockType: Body_BannerImage_BlockType;
  body_gallery_BlockType: Body_Gallery_BlockType;
  body_videoGallery_BlockType: Omit<Body_VideoGallery_BlockType, 'videos'> & { videos?: Maybe<Array<Maybe<ResolversParentTypes['videos_SuperTableField']>>> };
  videos_SuperTableField: ResolversParentTypes['videos_BlockType'];
  videos_BlockType: Videos_BlockType;
  SuperTableBlockInterface: ResolversParentTypes['videos_BlockType'] | ResolversParentTypes['faq_BlockType'] | ResolversParentTypes['list_BlockType'] | ResolversParentTypes['links_BlockType'];
  body_callout_BlockType: Body_Callout_BlockType;
  body_map_BlockType: Body_Map_BlockType;
  body_faq_BlockType: Omit<Body_Faq_BlockType, 'faq'> & { faq?: Maybe<Array<Maybe<ResolversParentTypes['faq_SuperTableField']>>> };
  faq_SuperTableField: ResolversParentTypes['faq_BlockType'];
  faq_BlockType: Faq_BlockType;
  body_media_BlockType: Body_Media_BlockType;
  body_audioPlayer_BlockType: Body_AudioPlayer_BlockType;
  body_pageComponent_BlockType: Body_PageComponent_BlockType;
  body_table_BlockType: Body_Table_BlockType;
  body_fileList_BlockType: Body_FileList_BlockType;
  body_events_BlockType: Body_Events_BlockType;
  body_surveyMonkeySignUp_BlockType: Body_SurveyMonkeySignUp_BlockType;
  body_socialMediaLinks_BlockType: Body_SocialMediaLinks_BlockType;
  body_featureList_BlockType: Omit<Body_FeatureList_BlockType, 'list'> & { list?: Maybe<Array<Maybe<ResolversParentTypes['list_SuperTableField']>>> };
  list_SuperTableField: ResolversParentTypes['list_BlockType'];
  list_BlockType: List_BlockType;
  body_embedCode_BlockType: Body_EmbedCode_BlockType;
  body_entriesMap_BlockType: Body_EntriesMap_BlockType;
  body_overviewLinks_BlockType: Omit<Body_OverviewLinks_BlockType, 'links'> & { links?: Maybe<Array<Maybe<ResolversParentTypes['links_SuperTableField']>>> };
  links_SuperTableField: ResolversParentTypes['links_BlockType'];
  links_BlockType: Links_BlockType;
  body_form_BlockType: Body_Form_BlockType;
  artWalks_artWalks_Entry: ArtWalks_ArtWalks_Entry;
  accessibility_difficultyRating_Entry: Accessibility_DifficultyRating_Entry;
  accessibility_default_Entry: Accessibility_Default_Entry;
  shortcuts_shortcut_BlockType: Shortcuts_Shortcut_BlockType;
  files_Asset: Files_Asset;
  User: User;
  UserInterface: ResolversParentTypes['User'];
  accessibilityTypes_Category: AccessibilityTypes_Category;
  RetourType: RetourType;
  RetourInterface: ResolversParentTypes['RetourType'];
  imagerx: Imagerx;
};

export type NgModuleDirectiveArgs = {   module: Scalars['String']; };

export type NgModuleDirectiveResolver<Result, Parent, ContextType = any, Args = NgModuleDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type NamedClientDirectiveArgs = {   name: Scalars['String']; };

export type NamedClientDirectiveResolver<Result, Parent, ContextType = any, Args = NamedClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  ping?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entries?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<QueryEntriesArgs, never>>;
  entryCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryEntryCountArgs, never>>;
  entry?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<QueryEntryArgs, never>>;
  assets?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<QueryAssetsArgs, never>>;
  assetCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryAssetCountArgs, never>>;
  asset?: Resolver<Maybe<ResolversTypes['AssetInterface']>, ParentType, ContextType, RequireFields<QueryAssetArgs, never>>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<QueryCategoriesArgs, never>>;
  categoryCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCategoryCountArgs, never>>;
  category?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<QueryCategoryArgs, never>>;
  imagerTransform?: Resolver<Maybe<Array<Maybe<ResolversTypes['ImagerTransformedImageInterface']>>>, ParentType, ContextType, RequireFields<QueryImagerTransformArgs, never>>;
};

export interface QueryArgumentScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['QueryArgument'], any> {
  name: 'QueryArgument';
}

export type EntryInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntryInterface'] = ResolversParentTypes['EntryInterface']> = {
  __resolveType: TypeResolveFn<'publicArtwork_publicArtwork_Entry' | 'artWalks_artWalks_Entry' | 'accessibility_difficultyRating_Entry' | 'accessibility_default_Entry', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<EntryInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRevision?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sourceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sourceUid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isUnpublishedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnsavedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  draftName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sectionHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<EntryInterfaceChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<EntryInterfaceDescendantsArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<EntryInterfaceParentArgs, never>>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<EntryInterfaceAncestorsArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<EntryInterfaceLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<EntryInterfacePrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<EntryInterfaceNextArgs, never>>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type AssetInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssetInterface'] = ResolversParentTypes['AssetInterface']> = {
  __resolveType: TypeResolveFn<'files_Asset', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<AssetInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  volumeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  folderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasFocalPoint?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  focalPoint?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<AssetInterfaceHeightArgs, never>>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<AssetInterfaceWidthArgs, never>>;
  img?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  srcset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<AssetInterfaceSrcsetArgs, 'sizes'>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<AssetInterfaceUrlArgs, never>>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  prev?: Resolver<Maybe<ResolversTypes['AssetInterface']>, ParentType, ContextType, RequireFields<AssetInterfacePrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['AssetInterface']>, ParentType, ContextType, RequireFields<AssetInterfaceNextArgs, never>>;
};

export type CategoryInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryInterface'] = ResolversParentTypes['CategoryInterface']> = {
  __resolveType: TypeResolveFn<'accessibilityTypes_Category', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<CategoryInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<CategoryInterfaceChildrenArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<CategoryInterfaceParentArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<CategoryInterfaceLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<CategoryInterfacePrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<CategoryInterfaceNextArgs, never>>;
};

export type ImagerTransformedImageInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImagerTransformedImageInterface'] = ResolversParentTypes['ImagerTransformedImageInterface']> = {
  __resolveType: TypeResolveFn<'imagerx', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isNew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  ping?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type ElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Element'] = ResolversParentTypes['Element']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Element_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ElementInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ElementInterface'] = ResolversParentTypes['ElementInterface']> = {
  __resolveType: TypeResolveFn<'Element' | 'publicArtwork_publicArtwork_Entry' | 'artist_artist_BlockType' | 'body_text_BlockType' | 'body_lineBreak_BlockType' | 'body_image_BlockType' | 'body_textImageCombo_BlockType' | 'body_bannerImage_BlockType' | 'body_gallery_BlockType' | 'body_videoGallery_BlockType' | 'videos_BlockType' | 'body_callout_BlockType' | 'body_map_BlockType' | 'body_faq_BlockType' | 'faq_BlockType' | 'body_media_BlockType' | 'body_audioPlayer_BlockType' | 'body_pageComponent_BlockType' | 'body_table_BlockType' | 'body_fileList_BlockType' | 'body_events_BlockType' | 'body_surveyMonkeySignUp_BlockType' | 'body_socialMediaLinks_BlockType' | 'body_featureList_BlockType' | 'list_BlockType' | 'body_embedCode_BlockType' | 'body_entriesMap_BlockType' | 'body_overviewLinks_BlockType' | 'links_BlockType' | 'body_form_BlockType' | 'artWalks_artWalks_Entry' | 'accessibility_difficultyRating_Entry' | 'accessibility_default_Entry' | 'shortcuts_shortcut_BlockType' | 'files_Asset' | 'User' | 'accessibilityTypes_Category', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<ElementInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
};

export type PublicArtwork_PublicArtwork_EntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['publicArtwork_publicArtwork_Entry'] = ResolversParentTypes['publicArtwork_publicArtwork_Entry']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_Entry_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRevision?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sourceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sourceUid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isUnpublishedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnsavedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  draftName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sectionHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryDescendantsArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryParentArgs, never>>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryAncestorsArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryPrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryNextArgs, never>>;
  geoLocation?: Resolver<Maybe<ResolversTypes['Ether_Map']>, ParentType, ContextType>;
  artist?: Resolver<Maybe<Array<Maybe<ResolversTypes['artist_MatrixField']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryArtistArgs, never>>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  featureImage?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryFeatureImageArgs, never>>;
  summaryRT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  body?: Resolver<Maybe<Array<Maybe<ResolversTypes['body_MatrixField']>>>, ParentType, ContextType, RequireFields<PublicArtwork_PublicArtwork_EntryBodyArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Ether_MapResolvers<ContextType = any, ParentType extends ResolversParentTypes['Ether_Map'] = ResolversParentTypes['Ether_Map']> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zoom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parts?: Resolver<Maybe<ResolversTypes['Ether_MapParts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Ether_MapPartsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Ether_MapParts'] = ResolversParentTypes['Ether_MapParts']> = {
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Artist_MatrixFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['artist_MatrixField'] = ResolversParentTypes['artist_MatrixField']> = {
  __resolveType: TypeResolveFn<'artist_artist_BlockType', ParentType, ContextType>;
};

export type Artist_Artist_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['artist_artist_BlockType'] = ResolversParentTypes['artist_artist_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Artist_Artist_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  artist?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MatrixBlockInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MatrixBlockInterface'] = ResolversParentTypes['MatrixBlockInterface']> = {
  __resolveType: TypeResolveFn<'artist_artist_BlockType' | 'body_text_BlockType' | 'body_lineBreak_BlockType' | 'body_image_BlockType' | 'body_textImageCombo_BlockType' | 'body_bannerImage_BlockType' | 'body_gallery_BlockType' | 'body_videoGallery_BlockType' | 'body_callout_BlockType' | 'body_map_BlockType' | 'body_faq_BlockType' | 'body_media_BlockType' | 'body_audioPlayer_BlockType' | 'body_pageComponent_BlockType' | 'body_table_BlockType' | 'body_fileList_BlockType' | 'body_events_BlockType' | 'body_surveyMonkeySignUp_BlockType' | 'body_socialMediaLinks_BlockType' | 'body_featureList_BlockType' | 'body_embedCode_BlockType' | 'body_entriesMap_BlockType' | 'body_overviewLinks_BlockType' | 'body_form_BlockType' | 'shortcuts_shortcut_BlockType', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MatrixBlockInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
};

export type Body_MatrixFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_MatrixField'] = ResolversParentTypes['body_MatrixField']> = {
  __resolveType: TypeResolveFn<'body_text_BlockType' | 'body_lineBreak_BlockType' | 'body_image_BlockType' | 'body_textImageCombo_BlockType' | 'body_bannerImage_BlockType' | 'body_gallery_BlockType' | 'body_videoGallery_BlockType' | 'body_callout_BlockType' | 'body_map_BlockType' | 'body_faq_BlockType' | 'body_media_BlockType' | 'body_audioPlayer_BlockType' | 'body_pageComponent_BlockType' | 'body_table_BlockType' | 'body_fileList_BlockType' | 'body_events_BlockType' | 'body_surveyMonkeySignUp_BlockType' | 'body_socialMediaLinks_BlockType' | 'body_featureList_BlockType' | 'body_embedCode_BlockType' | 'body_entriesMap_BlockType' | 'body_overviewLinks_BlockType' | 'body_form_BlockType', ParentType, ContextType>;
};

export type Body_Text_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_text_BlockType'] = ResolversParentTypes['body_text_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Text_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  columns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Text_BlockTypeColumnsArgs, never>>;
  columnsRuleColour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Text_BlockTypeColumnsRuleColourArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_LineBreak_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_lineBreak_BlockType'] = ResolversParentTypes['body_lineBreak_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_LineBreak_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lineThickness?: Resolver<Maybe<ResolversTypes['Number']>, ParentType, ContextType>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_LineBreak_BlockTypeColourArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface NumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Number'], any> {
  name: 'Number';
}

export type Body_Image_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_image_BlockType'] = ResolversParentTypes['body_image_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Image_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Body_Image_BlockTypeImageArgs, never>>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  captionText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Image_BlockTypeSizeArgs, never>>;
  hideZoomIcon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  showAsACircle?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_TextImageCombo_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_textImageCombo_BlockType'] = ResolversParentTypes['body_textImageCombo_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_TextImageCombo_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Body_TextImageCombo_BlockTypeImageArgs, never>>;
  imagePosition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_TextImageCombo_BlockTypeColourArgs, never>>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  button?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_BannerImage_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_bannerImage_BlockType'] = ResolversParentTypes['body_bannerImage_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_BannerImage_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Body_BannerImage_BlockTypeImageArgs, never>>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_BannerImage_BlockTypeSizeArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Gallery_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_gallery_BlockType'] = ResolversParentTypes['body_gallery_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Gallery_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Body_Gallery_BlockTypeImagesArgs, never>>;
  displayType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Gallery_BlockTypeDisplayTypeArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_VideoGallery_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_videoGallery_BlockType'] = ResolversParentTypes['body_videoGallery_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_VideoGallery_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<Maybe<ResolversTypes['videos_SuperTableField']>>>, ParentType, ContextType, RequireFields<Body_VideoGallery_BlockTypeVideosArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Videos_SuperTableFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['videos_SuperTableField'] = ResolversParentTypes['videos_SuperTableField']> = {
  __resolveType: TypeResolveFn<'videos_BlockType', ParentType, ContextType>;
};

export type Videos_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['videos_BlockType'] = ResolversParentTypes['videos_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Videos_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  video?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SuperTableBlockInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuperTableBlockInterface'] = ResolversParentTypes['SuperTableBlockInterface']> = {
  __resolveType: TypeResolveFn<'videos_BlockType' | 'faq_BlockType' | 'list_BlockType' | 'links_BlockType', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<SuperTableBlockInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
};

export type Body_Callout_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_callout_BlockType'] = ResolversParentTypes['body_callout_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Callout_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  background?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Callout_BlockTypeBackgroundArgs, never>>;
  sizing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Callout_BlockTypeSizingArgs, never>>;
  button?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Map_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_map_BlockType'] = ResolversParentTypes['body_map_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Map_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mapType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Map_BlockTypeMapTypeArgs, never>>;
  zoomLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_Map_BlockTypeZoomLevelArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Faq_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_faq_BlockType'] = ResolversParentTypes['body_faq_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Faq_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  faq?: Resolver<Maybe<Array<Maybe<ResolversTypes['faq_SuperTableField']>>>, ParentType, ContextType, RequireFields<Body_Faq_BlockTypeFaqArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Faq_SuperTableFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['faq_SuperTableField'] = ResolversParentTypes['faq_SuperTableField']> = {
  __resolveType: TypeResolveFn<'faq_BlockType', ParentType, ContextType>;
};

export type Faq_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['faq_BlockType'] = ResolversParentTypes['faq_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Faq_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  question?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Media_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_media_BlockType'] = ResolversParentTypes['body_media_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Media_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_AudioPlayer_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_audioPlayer_BlockType'] = ResolversParentTypes['body_audioPlayer_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_AudioPlayer_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  audioFile?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Body_AudioPlayer_BlockTypeAudioFileArgs, never>>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_PageComponent_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_pageComponent_BlockType'] = ResolversParentTypes['body_pageComponent_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_PageComponent_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  templateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entries?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Body_PageComponent_BlockTypeEntriesArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Table_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_table_BlockType'] = ResolversParentTypes['body_table_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Table_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_FileList_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_fileList_BlockType'] = ResolversParentTypes['body_fileList_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_FileList_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  files?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Body_FileList_BlockTypeFilesArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Events_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_events_BlockType'] = ResolversParentTypes['body_events_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Events_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventsCategory?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<Body_Events_BlockTypeEventsCategoryArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_SurveyMonkeySignUp_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_surveyMonkeySignUp_BlockType'] = ResolversParentTypes['body_surveyMonkeySignUp_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_SurveyMonkeySignUp_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  surveyId?: Resolver<Maybe<ResolversTypes['Number']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_SocialMediaLinks_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_socialMediaLinks_BlockType'] = ResolversParentTypes['body_socialMediaLinks_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_SocialMediaLinks_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_FeatureList_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_featureList_BlockType'] = ResolversParentTypes['body_featureList_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_FeatureList_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  list?: Resolver<Maybe<Array<Maybe<ResolversTypes['list_SuperTableField']>>>, ParentType, ContextType, RequireFields<Body_FeatureList_BlockTypeListArgs, never>>;
  listStyle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_FeatureList_BlockTypeListStyleArgs, never>>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_FeatureList_BlockTypeColourArgs, never>>;
  style1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_FeatureList_BlockTypeStyle1Args, never>>;
  style2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Body_FeatureList_BlockTypeStyle2Args, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type List_SuperTableFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['list_SuperTableField'] = ResolversParentTypes['list_SuperTableField']> = {
  __resolveType: TypeResolveFn<'list_BlockType', ParentType, ContextType>;
};

export type List_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['list_BlockType'] = ResolversParentTypes['list_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<List_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  listItem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_EmbedCode_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_embedCode_BlockType'] = ResolversParentTypes['body_embedCode_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_EmbedCode_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_EntriesMap_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_entriesMap_BlockType'] = ResolversParentTypes['body_entriesMap_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_EntriesMap_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mapSection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['Number']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_OverviewLinks_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_overviewLinks_BlockType'] = ResolversParentTypes['body_overviewLinks_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_OverviewLinks_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  links?: Resolver<Maybe<Array<Maybe<ResolversTypes['links_SuperTableField']>>>, ParentType, ContextType, RequireFields<Body_OverviewLinks_BlockTypeLinksArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Links_SuperTableFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['links_SuperTableField'] = ResolversParentTypes['links_SuperTableField']> = {
  __resolveType: TypeResolveFn<'links_BlockType', ParentType, ContextType>;
};

export type Links_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['links_BlockType'] = ResolversParentTypes['links_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Links_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<Links_BlockTypeImageArgs, never>>;
  header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Body_Form_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['body_form_BlockType'] = ResolversParentTypes['body_form_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Body_Form_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArtWalks_ArtWalks_EntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['artWalks_artWalks_Entry'] = ResolversParentTypes['artWalks_artWalks_Entry']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_Entry_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRevision?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sourceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sourceUid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isUnpublishedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnsavedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  draftName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sectionHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryDescendantsArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryParentArgs, never>>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryAncestorsArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryPrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryNextArgs, never>>;
  summaryRT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  artworks?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<ArtWalks_ArtWalks_EntryArtworksArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Accessibility_DifficultyRating_EntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['accessibility_difficultyRating_Entry'] = ResolversParentTypes['accessibility_difficultyRating_Entry']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_Entry_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRevision?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sourceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sourceUid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isUnpublishedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnsavedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  draftName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sectionHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryDescendantsArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryParentArgs, never>>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryAncestorsArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryPrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryNextArgs, never>>;
  difficultyRating?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Accessibility_DifficultyRating_EntryDifficultyRatingArgs, never>>;
  geoLocation?: Resolver<Maybe<ResolversTypes['Ether_Map']>, ParentType, ContextType>;
  geoLocationEnd?: Resolver<Maybe<ResolversTypes['Ether_Map']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Accessibility_Default_EntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['accessibility_default_Entry'] = ResolversParentTypes['accessibility_default_Entry']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Accessibility_Default_Entry_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRevision?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sourceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sourceUid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isUnpublishedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnsavedDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  draftName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sectionHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryChildrenArgs, never>>;
  descendants?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryDescendantsArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryParentArgs, never>>;
  ancestors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryAncestorsArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryPrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['EntryInterface']>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryNextArgs, never>>;
  accessibilityType?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<Accessibility_Default_EntryAccessibilityTypeArgs, never>>;
  geoLocation?: Resolver<Maybe<ResolversTypes['Ether_Map']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Shortcuts_Shortcut_BlockTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['shortcuts_shortcut_BlockType'] = ResolversParentTypes['shortcuts_shortcut_BlockType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Shortcuts_Shortcut_BlockType_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Files_AssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['files_Asset'] = ResolversParentTypes['files_Asset']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Files_Asset_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  volumeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  folderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasFocalPoint?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  focalPoint?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Files_AssetHeightArgs, never>>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<Files_AssetWidthArgs, never>>;
  img?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  srcset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Files_AssetSrcsetArgs, 'sizes'>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<Files_AssetUrlArgs, never>>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  prev?: Resolver<Maybe<ResolversTypes['AssetInterface']>, ParentType, ContextType, RequireFields<Files_AssetPrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['AssetInterface']>, ParentType, ContextType, RequireFields<Files_AssetNextArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<User_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  friendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInterface'] = ResolversParentTypes['UserInterface']> = {
  __resolveType: TypeResolveFn<'User', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<UserInterface_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  friendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type AccessibilityTypes_CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['accessibilityTypes_Category'] = ResolversParentTypes['accessibilityTypes_Category']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<AccessibilityTypes_Category_CountArgs, 'field'>>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  siteSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trashed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  root?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  structureId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupHandle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryChildrenArgs, never>>;
  parent?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryParentArgs, never>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localized?: Resolver<Maybe<Array<Maybe<ResolversTypes['CategoryInterface']>>>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryLocalizedArgs, never>>;
  prev?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryPrevArgs, never>>;
  next?: Resolver<Maybe<ResolversTypes['CategoryInterface']>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryNextArgs, never>>;
  hex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  menuIcon?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryMenuIconArgs, never>>;
  mapIcon?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssetInterface']>>>, ParentType, ContextType, RequireFields<AccessibilityTypes_CategoryMapIconArgs, never>>;
  smallIcon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RetourTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RetourType'] = ResolversParentTypes['RetourType']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  associatedElementId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  redirectSrcUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectSrcUrlParsed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectSrcMatch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectMatchType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectDestUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectHttpCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hitCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hitLastTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RetourInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['RetourInterface'] = ResolversParentTypes['RetourInterface']> = {
  __resolveType: TypeResolveFn<'RetourType', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  associatedElementId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  redirectSrcUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectSrcUrlParsed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectSrcMatch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectMatchType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectDestUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectHttpCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hitCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hitLastTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type ImagerxResolvers<ContextType = any, ParentType extends ResolversParentTypes['imagerx'] = ResolversParentTypes['imagerx']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isNew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Resolvers<ContextType = any> = {
  Query?: QueryResolvers<ContextType>;
  QueryArgument?: GraphQLScalarType;
  EntryInterface?: EntryInterfaceResolvers;
  DateTime?: GraphQLScalarType;
  AssetInterface?: AssetInterfaceResolvers;
  CategoryInterface?: CategoryInterfaceResolvers;
  ImagerTransformedImageInterface?: ImagerTransformedImageInterfaceResolvers;
  Mutation?: MutationResolvers<ContextType>;
  Element?: ElementResolvers<ContextType>;
  ElementInterface?: ElementInterfaceResolvers;
  publicArtwork_publicArtwork_Entry?: PublicArtwork_PublicArtwork_EntryResolvers<ContextType>;
  Ether_Map?: Ether_MapResolvers<ContextType>;
  Ether_MapParts?: Ether_MapPartsResolvers<ContextType>;
  artist_MatrixField?: Artist_MatrixFieldResolvers;
  artist_artist_BlockType?: Artist_Artist_BlockTypeResolvers<ContextType>;
  MatrixBlockInterface?: MatrixBlockInterfaceResolvers;
  body_MatrixField?: Body_MatrixFieldResolvers;
  body_text_BlockType?: Body_Text_BlockTypeResolvers<ContextType>;
  body_lineBreak_BlockType?: Body_LineBreak_BlockTypeResolvers<ContextType>;
  Number?: GraphQLScalarType;
  body_image_BlockType?: Body_Image_BlockTypeResolvers<ContextType>;
  body_textImageCombo_BlockType?: Body_TextImageCombo_BlockTypeResolvers<ContextType>;
  body_bannerImage_BlockType?: Body_BannerImage_BlockTypeResolvers<ContextType>;
  body_gallery_BlockType?: Body_Gallery_BlockTypeResolvers<ContextType>;
  body_videoGallery_BlockType?: Body_VideoGallery_BlockTypeResolvers<ContextType>;
  videos_SuperTableField?: Videos_SuperTableFieldResolvers;
  videos_BlockType?: Videos_BlockTypeResolvers<ContextType>;
  SuperTableBlockInterface?: SuperTableBlockInterfaceResolvers;
  body_callout_BlockType?: Body_Callout_BlockTypeResolvers<ContextType>;
  body_map_BlockType?: Body_Map_BlockTypeResolvers<ContextType>;
  body_faq_BlockType?: Body_Faq_BlockTypeResolvers<ContextType>;
  faq_SuperTableField?: Faq_SuperTableFieldResolvers;
  faq_BlockType?: Faq_BlockTypeResolvers<ContextType>;
  body_media_BlockType?: Body_Media_BlockTypeResolvers<ContextType>;
  body_audioPlayer_BlockType?: Body_AudioPlayer_BlockTypeResolvers<ContextType>;
  body_pageComponent_BlockType?: Body_PageComponent_BlockTypeResolvers<ContextType>;
  body_table_BlockType?: Body_Table_BlockTypeResolvers<ContextType>;
  body_fileList_BlockType?: Body_FileList_BlockTypeResolvers<ContextType>;
  body_events_BlockType?: Body_Events_BlockTypeResolvers<ContextType>;
  body_surveyMonkeySignUp_BlockType?: Body_SurveyMonkeySignUp_BlockTypeResolvers<ContextType>;
  body_socialMediaLinks_BlockType?: Body_SocialMediaLinks_BlockTypeResolvers<ContextType>;
  body_featureList_BlockType?: Body_FeatureList_BlockTypeResolvers<ContextType>;
  list_SuperTableField?: List_SuperTableFieldResolvers;
  list_BlockType?: List_BlockTypeResolvers<ContextType>;
  body_embedCode_BlockType?: Body_EmbedCode_BlockTypeResolvers<ContextType>;
  body_entriesMap_BlockType?: Body_EntriesMap_BlockTypeResolvers<ContextType>;
  body_overviewLinks_BlockType?: Body_OverviewLinks_BlockTypeResolvers<ContextType>;
  links_SuperTableField?: Links_SuperTableFieldResolvers;
  links_BlockType?: Links_BlockTypeResolvers<ContextType>;
  body_form_BlockType?: Body_Form_BlockTypeResolvers<ContextType>;
  artWalks_artWalks_Entry?: ArtWalks_ArtWalks_EntryResolvers<ContextType>;
  accessibility_difficultyRating_Entry?: Accessibility_DifficultyRating_EntryResolvers<ContextType>;
  accessibility_default_Entry?: Accessibility_Default_EntryResolvers<ContextType>;
  shortcuts_shortcut_BlockType?: Shortcuts_Shortcut_BlockTypeResolvers<ContextType>;
  files_Asset?: Files_AssetResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserInterface?: UserInterfaceResolvers;
  accessibilityTypes_Category?: AccessibilityTypes_CategoryResolvers<ContextType>;
  RetourType?: RetourTypeResolvers<ContextType>;
  RetourInterface?: RetourInterfaceResolvers;
  imagerx?: ImagerxResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  NgModule?: NgModuleDirectiveResolver<any, any, ContextType>;
  namedClient?: NamedClientDirectiveResolver<any, any, ContextType>;
};


/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<ContextType>;
export const AccessibilityTypesDocument = gql`
    query accessibilityTypes {
  categories(group: "accessibilityTypes") {
    ... on accessibilityTypes_Category {
      title
      slug
      hex
      smallIcon
      mapIcon {
        icon: url
      }
      menuIcon {
        icon: url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccessibilityTypesGQL extends Apollo.Query<AccessibilityTypesQuery, AccessibilityTypesQueryVariables> {
    document = AccessibilityTypesDocument;
    
  }
export const AccessibilityDocument = gql`
    query accessibility {
  entries(section: "accessibility", type: "default", enabledForSite: true) {
    id
    title
    slug
    ... on accessibility_default_Entry {
      accessibilityType {
        ... on accessibilityTypes_Category {
          title
          slug
          hex
          smallIcon
          mapIcon {
            icon: url
          }
          menuIcon {
            icon: url
          }
        }
      }
      position: geoLocation {
        lat
        lng
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccessibilityGQL extends Apollo.Query<AccessibilityQuery, AccessibilityQueryVariables> {
    document = AccessibilityDocument;
    
  }
export const DifficultyRatingsDocument = gql`
    query difficultyRatings {
  entries(
    section: "accessibility"
    type: "difficultyRating"
    enabledForSite: true
  ) {
    id
    title
    slug
    ... on accessibility_difficultyRating_Entry {
      difficultyRating
      position: geoLocation {
        lat
        lng
      }
      position2: geoLocationEnd {
        lat
        lng
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DifficultyRatingsGQL extends Apollo.Query<DifficultyRatingsQuery, DifficultyRatingsQueryVariables> {
    document = DifficultyRatingsDocument;
    
  }