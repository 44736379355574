import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

import {ToastrModule} from "ngx-toastr";
import {GoogleMapsModule} from "@angular/google-maps";
import { FaqComponent } from './routes/faq/faq.component';
import { HomeComponent } from './routes/home/home.component';
import { ContactComponent } from './routes/contact/contact.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MapComponent } from './components/map/map.component';
import { ContentComponent } from './routes/content/content.component';
import {DataService} from "./services/data.service";
import { IframeAutoHeightDirective } from './directives/iframe-auto-height.directive';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import Bugsnag from '@bugsnag/js';

Bugsnag.start({
  apiKey: 'bcfab5a2beb03ed13fe8d7a5ec195918',
  releaseStage: environment.env
})
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    HomeComponent,
    ContactComponent,
    MapComponent,
    ContentComponent,
    IframeAutoHeightDirective,
    MenuComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
    GoogleMapsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      newestOnTop: false,
    }),
    GraphQLModule,
    HttpClientModule,
  ],
  providers: [
    DataService,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
