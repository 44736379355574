<div class="container-fluid top">
  <div class="left logo" (click)="goHome()">
    <img src="/assets/logo.svg" alt="ASTC Logo">
  </div>

  <div class="right">
    <div class="row">
      <div class="col-md-6 text">
        <h6 class="d-md-block">Alice Springs</h6>
        <h2>Accessibility Map</h2>
        <p class="d-none d-md-block">Working for the community</p>
      </div>
    </div>
  </div>
</div>

<div class="bottom d-print-none">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 menu d-flex">
        <div (click)="dataService.menu.toggle()" class="btn map-btn d-xxl-none" *ngIf="dataService.menu">
          <i class="fa fa-fw" [class.fa-bars]="!dataService.menu.show" [class.fa-close]="dataService.menu.show"></i>
        </div>
        <div (click)="goHome()" class="btn map-btn">
          <i class="fa fa-fw fa-map-o"></i>
        </div>
        <div (click)="centreGPS()" class="btn map-btn">
          <i class="fa fa-fw fa-compass"></i>
        </div>
        <div (click)="dataService.menu.toggle(false)" routerLink="faq" preserveFragment="true" [queryParamsHandling]="'preserve'" class="btn map-btn">
          <i class="fa fa-fw fa-question-circle"></i>
        </div>

        <div (click)="print()" class="btn map-btn ml-auto">
          <i class="fa fa-fw fa-print"></i>
        </div>
        <div (click)="goBack()" class="btn map-btn">
          <i class="fa fa-fw fa-arrow-left"></i>
        </div>
      </div>
    </div>
  </div>
</div>
