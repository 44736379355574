import {AfterViewInit, ChangeDetectionStrategy, Component, NgZone, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {SwUpdate} from "@angular/service-worker";
import {take} from "rxjs/operators";
import {NavigationEnd, Router} from "@angular/router";
import {DataService} from "./services/data.service";
import {MapComponent} from "./components/map/map.component";
import {MenuComponent} from "./components/menu/menu.component";
import {HeaderComponent} from "./components/header/header.component";
import {FooterComponent} from "./components/footer/footer.component";
import {Angulartics2GoogleAnalytics} from "angulartics2/ga";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  @ViewChild(MapComponent, { static: false }) map: MapComponent
  @ViewChild(MenuComponent, { static: false }) menu: MenuComponent
  @ViewChild(HeaderComponent, { static: false }) header: HeaderComponent
  @ViewChild(FooterComponent, { static: false }) footer: FooterComponent

  constructor(
    private swUpdate: SwUpdate,
    private toastr: ToastrService,
    private zone: NgZone,
    public router: Router,
    public dataService: DataService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    angulartics2GoogleAnalytics.startTracking();

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        console.log('New update available');
        this.toastr.info('Tap To Refresh', 'APP Updated!', {
          disableTimeOut: true,
        }).onTap
          .pipe(take(1))
          .subscribe(() => this.updateToLatest());
      });
    }

    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.checkForUpdate();
      }, 10000);
    });

  }

  updateToLatest(): void {
    console.log('Updating to latest version.');
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(() => {
        console.log('Checking for updates...');
      }).catch((err) => {
        console.error('Error when checking for update', err);
      });
    }
  }

  ngAfterViewInit(): void {
    this.dataService.map = this.map;
    this.dataService.menu = this.menu;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url.split("?")[0].split("#")[0] === '/') {
        if (this.map.focusedMarkers.length === 1){
          this.dataService.findItem(this.map.focusedMarkers[0]).subscribe(marker => {
            // @ts-ignore
            this.map.centreMap(marker.position, this.map.map.getZoom())
          })
        } else if(this.map.focusedMarkers.length > 1) {
          this.map.centreMap(this.dataService.map.centre, this.map.map.getZoom())
        }
        this.map.clearFocusedMarkers();
      }
    })
  }

}
