import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {DataService} from "../../services/data.service";
import {Router} from "@angular/router";
import {ApolloQueryResult} from "@apollo/client/core";
import {AccessibilityTypesQuery} from "../../../generated/graphql";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {

  public show: boolean = false;

  constructor(public router: Router, public dataService: DataService, public cdRef: ChangeDetectorRef) { }

  // public getMenuItem(url: string): void {
  //   this.dataService.map.stopRendering()
  //   this.toggle(false);
  //   this.router.navigate([url],{queryParamsHandling: 'preserve', preserveFragment: true});
  // }

  public toggle(state: boolean = null): void {
    this.show = state !== null ? state : !this.show;
    if (this.show) this.dataService.refresh();
    this.cdRef.detectChanges();
  }

  public toggleType(slug: string): void {
    if (this.dataService.hidden.includes(slug)) {
      this.dataService.hidden.splice(this.dataService.hidden.findIndex(i => i === slug), 1);
    } else {
      this.dataService.hidden.push(slug);
    }

    this.dataService.$hidden.next(this.dataService.hidden);
    // console.log(this.dataService.hidden)
  }

  public toggleDifficulty(): void {
    this.dataService.hideDifficultyRatings = !this.dataService.hideDifficultyRatings;
    this.cdRef.detectChanges();
  }

  public deselectAll(): void {
    this.dataService.hidden = [];
    this.dataService.$hidden.next(this.dataService.hidden);
  }

  public selectAll(types: ApolloQueryResult<AccessibilityTypesQuery>): void {
    this.dataService.hidden = [];
    types.data.categories.forEach(type => {
      this.dataService.hidden.push(type.slug);
    })
    this.dataService.$hidden.next(this.dataService.hidden);
  }


}
