<div class="content quarter">
  <div class="container-fluid">
    <div class="row">
      <div class="col" *ngIf="$metaContent">

        <ng-container *ngIf="($metaContent | async) as metaData">

          <div class="d-flex buttons">
            <div class="ml-auto">
              <a (click)="startDirections(metaData, true)" class="btn btn-primary mr-1" [class.disabled]="!routeData">Show Path</a>
              <a (click)="startDirections(metaData)" class="btn btn-primary mr-1" [class.disabled]="!routeData">Directions</a>
              <a [routerLink]="'/'" preserveFragment="true" [queryParamsHandling]="'preserve'" class="btn btn-primary">
                <i class="fa fa-fw fa-close"></i>
              </a>
            </div>
          </div>

          <div class="meta-data d-flex align-items-center mb-3">

            <div class="image">
              <img [src]="metaData.accessibilityType[0].menuIcon[0].icon">
            </div>

            <h1 class="m-0 ml-3">{{ metaData.title }}</h1>

          </div>

          <p class="font-weight-bold">{{ metaData.accessibilityType[0].title }}</p>
          <p class="distance" *ngIf="routeData">{{routeData.distance.text}}, {{routeData.duration.text}} (walking)</p>
          <p class="distance" *ngIf="!routeData">Loading...</p>

        </ng-container>

      </div>
    </div>
  </div>
</div>
