<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="mb-5">FAQ</h1>
        <p>
          FAQ Content Here
        </p>
      </div>
    </div>
  </div>
</div>
